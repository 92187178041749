import React, { useState, useEffect } from "react";

import classnames from 'classnames';
import { useApp } from "../../Data/AppContext";
import useWindowDimensions from "../../Hooks/WindowDimensions";

import { BtnBlock } from "..";
import CardItem from "./CardItem";
//import CardMore from "./CardMore";

import Nft3840 from '../../assets/img/adaptive_background/nft3840.png';
import Nft2560 from '../../assets/img/adaptive_background/nft2560.png';
import Nft2048 from '../../assets/img/adaptive_background/nft2048.png';
import Nft1920 from '../../assets/img/adaptive_background/nft1920.png';
import Nft1440 from '../../assets/img/adaptive_background/nft1440.png';
import Nft1366 from '../../assets/img/adaptive_background/nft1366.png';
import Nft1280 from '../../assets/img/adaptive_background/nft1280.png';
import Nft1024 from '../../assets/img/adaptive_background/nft1024.png';
import Nft768 from '../../assets/img/adaptive_background/nft768.png';

import {
    ALL,
    UNSTAKE_PAGE,
    STAKE_PAGE,
    DAILY_POOL,
    STAKING_DURATION_1_DAY,
    PRE_LAUNCH_POOL,
    STAKING_DURATION_FIXED,
    ALL_TERM
} from "../../Constants";


const CardList = ({
    stakeOrUnstakeBtnText,
    stakeOrUnstakeBtnHandler,
    selectedCards,
    selectCard,
    selectedUnstekedCards,
    setSelectUnstekedCard,
    type,
    page,
    pageType,
    pageTypeSecond,
    setPage,
}) => {

    const { stakedList, unstakedList } = useApp();

    const { width } = useWindowDimensions();

    let contentImg = null;
    
    if (width <= 4096 && width > 3840 ) {
        contentImg = Nft3840;
    } else if (width <= 3840 && width > 2580) {
        contentImg = Nft3840;
    } else if (width <= 2580 && width > 2048) {
        contentImg = Nft2560;
    } else if (width <= 2048 && width > 1920) {
        contentImg = Nft2048;
    } else if (width <= 1920 && width > 1440) {
        contentImg = Nft1920;
    } else if (width <= 1440 && width > 1366) {
        contentImg = Nft1440;
    } else if (width <= 1366 && width > 1280) {
        contentImg = Nft1366;
    } else if (width <= 1280 && width > 1024) {
        contentImg = Nft1280;
    } else if (width <= 1024 && width > 768) {
        contentImg = Nft1024;
    } else if (width <= 768 ) {
        contentImg = Nft768;
    }

    const [itemsToShow, setItemsToShow] = useState([]);
    const [selectedRarity, setSelectedRarity] = useState('');
    const [selectedTerm, setSelectedTerm] = useState('');

    useEffect(() => {
        const items = page === UNSTAKE_PAGE ? stakedList : unstakedList

        const getRarityFilterCondition = (rarity) => !!selectedRarity && selectedRarity !== ALL
            ? selectedRarity.toLowerCase() === rarity.toLowerCase()
            : true;

        const getTermFilterCondition = (stakingInfo) => {
            return !!selectedTerm && selectedTerm !== ALL_TERM && !!stakingInfo
                ? (page !== UNSTAKE_PAGE)
                    || (selectedTerm === DAILY_POOL && stakingInfo.stakingDuration === STAKING_DURATION_1_DAY)
                    || (selectedTerm === PRE_LAUNCH_POOL && stakingInfo.stakingDuration === STAKING_DURATION_FIXED)
                : true;
        };

        setItemsToShow(items.filter(({ data: { rarity }, stakingInfo }) => {
            return getRarityFilterCondition(rarity) && getTermFilterCondition(stakingInfo);
        }));
    }, [page, selectedRarity, selectedTerm, unstakedList, stakedList]);

    return (
        <div className='card-block'>
            <div className="img__block">
                <img src={ contentImg } alt="" />
            </div>

            <div className="container">
                <BtnBlock 
                    pageType={ pageType }
                    pageTypeSecond={ pageTypeSecond }
                    scroll={true}
                    stakeOrUnstakeBtnText={stakeOrUnstakeBtnText}
                    stakeOrUnstakeBtnHandler={stakeOrUnstakeBtnHandler}
                    page={page}
                    selectedCards={selectedCards}
                    type={type}
                    setPage={setPage}
                    setSelectedRarity={setSelectedRarity}
                    setSelectedTerm={setSelectedTerm}
                />

                <div className="card__wrapper">
                    <div className={classnames('card-block__wrapper', {'show': page === UNSTAKE_PAGE})}>
                        { itemsToShow.map(item =>
                            <CardItem
                                key={item.asset_id}
                                type={type}
                                item={item}
                                selectCard={setSelectUnstekedCard}
                                selectedCards={selectedUnstekedCards}
                            />
                        )}

                        {/*<CardMore />*/}
                    </div>

                    <div className={classnames('card-block__wrapper', {'show': page === STAKE_PAGE})}>
                        { itemsToShow.map(item =>
                            <CardItem
                                key={item.asset_id}
                                item={item}
                                type={type}
                                selectCard={selectCard}
                                selectedCards={selectedCards}
                            />
                        ) }

                        {/* <CardMore /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardList;