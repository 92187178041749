import React, { useContext, useEffect, useState } from "react";

import { UALContext } from "ual-reactjs-renderer";
import { toast } from "react-toastify";
import classnames from 'classnames';
import { useApp } from "../../Data/AppContext";

import SimpleButton from "../Ui/SimpleButton/SimpleButton";

import { fetchStakedItems, stakeItems } from "../../Services";

const StakingModal = ({ hidePopupHandler }) => {
    const { activeUser } = useContext(UALContext);

    const time = ['24 hours', 'Until May 1'];
    const [selector, setSelector] = useState({ show: false, value: '24 hours', });
    const [stakingReward, setStakingReward] = useState(0);

    const {
        setUserStakedItems,
        selectedItemsForStake,
        setSelectedItemsForStake,
        removeItemsFromUserNotStakedItems
    } = useApp();

    useEffect(() => {
        if (selector.value === '24 hours')
            setStakingReward(selectedItemsForStake.reduce((total, { miningInfo: { dailyPool } }) => total + dailyPool, 0));

        if (selector.value === 'Until May 1')
            setStakingReward(selectedItemsForStake.reduce((total, { miningInfo: { preLaunchPool } }) => total + preLaunchPool, 0));
    }, [selector, selectedItemsForStake]);

    const selectorToggle = (value) => {
        return setSelector({...selector, show: value});
    }

    const selectTime = (value) => {
        return setSelector({ show: false, value: value });
    }

    const handleStake = () => {
        if (!selectedItemsForStake.length) {
            toast.warning('Select items for stake');

            return hidePopupHandler();
        }

        stakeItems({
            activeUser,
            items: selectedItemsForStake.map(({ asset_id }) => asset_id),
            fixed: selector.value !== '24 hours'
        })
            .then(() => {
                fetchStakedItems(({ account: activeUser.accountName }))
                    .then((items) => setUserStakedItems(items))
                    .catch(e => console.log(e));

                removeItemsFromUserNotStakedItems(selectedItemsForStake);
                setSelectedItemsForStake([]);
                hidePopupHandler();

                toast.success('Stacked');
            })
            .catch((e) => toast.error(e));
    };

    return (
        <div className='content_block staking_modal'>
            <div className="staking">
                <div className="staking__title">
                    Staking confirmation
                </div>
            </div>

            <div className="staking_content">
                <div className="time_container">
                    <div className="title">
                        Select lock time
                    </div>

                    <div className={classnames('time__toggle', {open: selector.show})}>
                        <div
                            className='selected_value'
                            onClick={() => selectorToggle(!selector.show)}
                        >
                            <span>{ selector.value }</span>
                        </div>

                        <div className={classnames('time__list', {show: selector.show})}>
                            { time.map((item, index) =>
                                <div
                                    key={index}
                                    className='time'
                                    data-time={item}
                                    onClick={()=>selectTime(item)}
                                >
                                    { item }
                                </div>
                            ) }
                        </div>
                    </div>
                </div>

                {/*TODO: remove hardcode, what is select value?*/}
                {/*<div className="staking_list">*/}
                {/*    <div className="title">*/}
                {/*        Select value*/}
                {/*    </div>*/}
                {/*    <div className="value">*/}
                {/*        2143124*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="staking_list">
                    <div className="title">
                        Staking reward
                    </div>
                    <div className="value">
                        { stakingReward }
                    </div>
                </div>
            </div>

            <div className="button_blocks">
                <SimpleButton handler={hidePopupHandler}>
                    Cancel
                </SimpleButton>

                <SimpleButton handler={handleStake}>
                    Stake
                </SimpleButton>
            </div>
        </div>
    )
}

export default StakingModal;