import React, {useState} from "react";
import { useApp } from "../../Data/AppContext";

import Caption from "../../Components/Ui/Caption/Caption";
import MobileCaption from "../../Components/Ui/MobileCaption/MobileCaption";
import useWindowDimensions from "../../Hooks/WindowDimensions";
import { ContentContainer, StatusBarStaking, CardList, RewardSidebar, MobileStatusBarStaking } from "../../Components";

const RewardsPage = () => {
    const { sidebar, isAuthenticated, toggleRewardsPopupHandler } = useApp();
    const { width } = useWindowDimensions();

    const showRewardsInfo = () => {
        toggleRewardsPopupHandler(true);
    }

    const [selectedCards, setSelectCard] = useState([]);
    const [selectedUnstekedCards, setSelectUnstekedCard] = useState([]);

    const selectCard = (id) => {
        const items = selectedCards;
        const index = items.findIndex(item => item === id);

        if (index !== -1)
            return setSelectCard(items.filter(item => item !== id));

        setSelectCard([...items, id]);
    }

    const selectStakeCard = (id) => {
        const items = selectedUnstekedCards;
        const index = items.findIndex(item => item === id);

        if (index !== -1)
            return setSelectUnstekedCard(items.filter(item => item !== id));

        setSelectUnstekedCard([...items, id]);
    }

    return (
        <>
            <ContentContainer sidebar={sidebar}>
                <div className='nft_page auth rewards_page'>
                    <Caption isAuthenticated={isAuthenticated}>Rewards</Caption>

                    <MobileCaption text='Rewards' btnText = 'Rewards Info' handler = { showRewardsInfo }/>

                    <div className='nft_page__content'>
                        <StatusBarStaking type={'rewards'}/>

                        <MobileStatusBarStaking type={'rewards'}/>

                        <CardList 
                            pageType= { width > 812 ? 'Reward NFT Collection' : 'NFT Collection' }
                            pageTypeSecond= { width > 812 ? 'Сlaimed NFT Reward' : 'Claimed NFT' }
                            selectedCards={selectedCards}
                            selectCard={selectCard}
                            selectedUnstekedCards = {selectedUnstekedCards}
                            setSelectUnstekedCard = {selectStakeCard}
                            type={'rewards'}
                        />

                    </div>
                </div>
            </ContentContainer>

            { sidebar && <RewardSidebar /> }
        </>
    )
}

export default RewardsPage;