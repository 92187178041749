import React from 'react';
import { useApp } from '../../Data/AppContext';

import Caption from '../../Components/Ui/Caption/Caption';
import MobileCaption from '../../Components/Ui/MobileCaption/MobileCaption';
import { ContentContainer, Sidebar, StatsInformation, TransactionTable } from '../../Components';

const MyStatsPage = () => {
    const { isAuthenticated, toggleStakingPopupHandler, sidebar } = useApp();

    const showStakingInfo = () => {
        toggleStakingPopupHandler(true);
    }
    
    return  (
        <>
            <ContentContainer>
                <div className='stats_page'>
                    <Caption isAuthenticated={isAuthenticated}>
                        My Operations
                    </Caption>

                    <MobileCaption
                        text='My Operations'
                        btnText = 'Staking Info'
                        handler = { showStakingInfo }
                    />

                    <div className='stats_page__content'>
                        <StatsInformation />
                        <h2 className='transaction__title'>Transaction History</h2>
                        <TransactionTable />
                    </div>
                </div>

            </ContentContainer>

            { sidebar && <Sidebar /> }
        </>
    )
}

export default MyStatsPage;