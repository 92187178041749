import React from "react";
import classnames from 'classnames';
import SimpleButton from "../SimpleButton/SimpleButton";

const MobileCaption = ({ text, btnText, handler, position }) => {
    return (
        <div className={ classnames('mobile_caption', { center: position }) }>
            <h1>{ text }</h1>

            { btnText &&
                <SimpleButton handler = { handler } active={true}>
                    { btnText }
                </SimpleButton>
            }
            
        </div>
    )
}

export default MobileCaption;