import React from "react";
import SimpleButton from "../Ui/SimpleButton/SimpleButton";

const Congralutations = () => {
    return (
        <div className='content_block congratulations_modal'>
            <h2>
                Congratulations!
            </h2>
            <div className="text">
                Transaction was successful!
            </div>
            <div className="button_blocks">
                <SimpleButton >
                    Ok
                </SimpleButton>
            </div>
        </div>
    )
}

export default Congralutations;