import React, { useState } from "react";
import { useApp } from "../../Data/AppContext";

import Caption from "../../Components/Ui/Caption/Caption";
import MobileCaption from '../../Components/Ui/MobileCaption/MobileCaption';
import { ContentContainer, Information, BtnBlock, StatusBarStaking, CardList, Sidebar } from "../../Components";

import { STAKE_PAGE, STAKING_LIMIT, UNSTAKE_PAGE } from "../../Constants";

const NFTStakingPage = () => {
    const {
        userData,
        sidebar,
        stakedList,
        unstakedList,
        isAuthenticated,
        showPopupHandler,
        selectedItemsForStake,
        setSelectedItemsForStake,
        selectedItemsForUnstake,
        setSelectedItemsForUnstake,
        toggleStakingPopupHandler,
    } = useApp();

    const showStakingInfo = () => {
        toggleStakingPopupHandler(true);
    }

    const [page, setPage] = useState(UNSTAKE_PAGE);
   
    const selectCard = (item) => {
        const index = selectedItemsForStake.findIndex(({ asset_id }) => asset_id === item.asset_id);

        if (index !== -1)
            return setSelectedItemsForStake(selectedItemsForStake.filter(({ asset_id }) => asset_id !== item.asset_id));

        setSelectedItemsForStake([...selectedItemsForStake, item]);
    }

    const selectStakeCard = (item) => {
        const index = selectedItemsForUnstake.findIndex(({ asset_id }) => asset_id === item.asset_id);

        if (index !== -1)
            return setSelectedItemsForUnstake(selectedItemsForUnstake.filter(({ asset_id }) => asset_id !== item.asset_id));

        setSelectedItemsForUnstake([...selectedItemsForUnstake, item]);
    }

    const getStakeOrUnstakeBtnText = () => {
        switch (page) {
            case STAKE_PAGE:
                return selectedItemsForStake.length === 0 ? 'Stake All' : 'Stake Selected';

            case UNSTAKE_PAGE:
                return selectedItemsForUnstake.length === 0 ? 'Unstake All' : 'Unstake Selected';

            default:
                return '';
        }
    }

    const getStakeOrUnstakeBtnHandler = () => {
        switch (page) {
            case STAKE_PAGE:
                return () => {
                    if (selectedItemsForStake.length === 0)
                        setSelectedItemsForStake(unstakedList.slice(0, STAKING_LIMIT));

                    showPopupHandler('staking');
                }

            case UNSTAKE_PAGE:
                return () => {
                    if (selectedItemsForUnstake.length === 0)
                        setSelectedItemsForUnstake(stakedList.slice(0, STAKING_LIMIT));

                    showPopupHandler('unstake');
                }

            default:
                return () => {};
        }
    }

    const stakeOrUnstakeBtnText = getStakeOrUnstakeBtnText();
    const stakeOrUnstakeBtnHandler = getStakeOrUnstakeBtnHandler();

    if (isAuthenticated) {
        return (
            <>
                <ContentContainer sidebar={sidebar}>
                    <div className='nft_page auth'>
                        <Caption
                            isAuthenticated={isAuthenticated}
                        >
                            NFT Staking
                        </Caption>

                        <MobileCaption
                            text='NFT Staking'
                            btnText='Staking Info'
                            handler={ showStakingInfo }
                        />

                        <div className='nft_page__content'>
                            <StatusBarStaking
                                type={'nft'}
                                setPage={setPage}
                                page={page}
                                pageType={'Staked NFTs'}
                                pageTypeSecond={'Not staked NFTs'}
                            />

                             {/*<MobileStatusBarStaking */}
                             {/*    type={'nft'} */}
                             {/*    setPage={setPage} */}
                             {/*    page={page} */}
                             {/*    pageType={'Staked NFTs'}*/}
                             {/*    pageTypeSecond={'Not staked NFTs'}*/}
                             {/*/> */}

                            <CardList
                                pageType={'Staked NFTs'} 
                                pageTypeSecond={'Not staked NFTs'}
                                stakeOrUnstakeBtnText={stakeOrUnstakeBtnText}
                                stakeOrUnstakeBtnHandler={stakeOrUnstakeBtnHandler}
                                selectedCards={ selectedItemsForStake }
                                selectCard={ selectCard }
                                selectedUnstekedCards={ selectedItemsForUnstake }
                                setSelectUnstekedCard={ selectStakeCard }
                                type={'nft'}
                                page={ page }
                                setPage={ setPage }
                            />
                        </div>
                    </div>
                </ContentContainer>

                { sidebar && <Sidebar /> }
            </>
        )
    }

    return (
        <ContentContainer sidebar={sidebar}>
            <div className='nft_page'>
                <Caption isAuthenticated={isAuthenticated}>
                    NFT Staking
                </Caption>

                <MobileCaption text='NFT Staking' position='center' />

                <div className='nft_page__content'>
                    <BtnBlock />

                    { (userData && userData.buyBFT) ? "" : < Information /> }
                </div>
            </div>
        </ContentContainer>
    )
}

export default NFTStakingPage;