import React from "react";
import classnames from 'classnames';

const FaqItem = ({ id, question, answer, active, setActive }) => {

    const activeItemHandler = (id) => {
        if (active === id) return setActive();

        setActive(id);
    }

    return (
        <div className={ classnames('faq_item') }>
                <div
                    className={ classnames('faq_item__title', { 'active': active === id}) }
                    onClick={ () => activeItemHandler(id) }
                >
                    { question }
                </div>
                <div className={ classnames('faq_item__content', {'active': active === id }) }>
                    { answer }
                </div>
        </div>
    )
}

export default FaqItem;