import React from "react";

import { useApp } from "../../Data/AppContext";
import MobileNavList from "./MobileNavList";

const MobileMenu = () => {
    const { isAuthenticated } = useApp();

    return (
        <div className='menu_block__mobile'>
            <MobileNavList isAuthenticated = { isAuthenticated } />
        </div>
    )
}

export default MobileMenu;