import React from "react";
import { useApp } from '../../Data/AppContext';

import { ContentContainer, BtnBlock, Sidebar } from "../../Components";
import MobileCaption from "../../Components/Ui/MobileCaption/MobileCaption";
import Caption from "../../Components/Ui/Caption/Caption";
import FaqList from "./FaqList";

const InformationPage = () => {
    const { isAuthenticated, sidebar } = useApp();

    return (
        <>
            <ContentContainer>
                <div className='information_page'>
                    <Caption isAuthenticated = { isAuthenticated }>FAQ</Caption>
                    <MobileCaption text='FAQ' position='center' />
                    <div className="information_page__content">
                        <BtnBlock faq={true}/>
                        <FaqList />
                    </div>
                </div>
            </ContentContainer>
            {sidebar && <Sidebar />}
        </>
    )
}

export default InformationPage;