import React from 'react';
import classnames from 'classnames';

import { ReactComponent as DangerIcon } from '../../assets/img/icons/danger.svg';
import ScreenImg from '../../assets/img/screen.png';

const Alert = ({ type, caption, text, subtext, img, label }) => {
    return (
        <div className={classnames('alert', type)}>
            { type === 'auth' && <img src={ ScreenImg } alt="" className='alert__screen'/> }
            
            <div className="alert_content">
                { label && <span className='label'>My Operations</span> }
                { img && <DangerIcon className='alert__icon'/> }
                <h3 className='alert__caption'>{ caption }</h3>
                <p className='alert__text'>{ text }</p>
                <p className='alert__text'>{ subtext }</p>
            </div>
        </div>
    )
} 

export default Alert; 