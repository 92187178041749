import React, { useEffect, useState } from "react";

import { useApp } from "../../Data/AppContext";
import classnames from 'classnames';

import { ReactComponent as InfoIcon } from '../../assets/img/icons/icon.svg';

import SidImg1_4096 from '../../assets/img/adaptive_background/sidebar4096-1.png';
import SidImg1_3840 from '../../assets/img/adaptive_background/sidebar3840-1.png';
import SidImg1_2560 from '../../assets/img/adaptive_background/sidebar2560-1.png';
import SidImg1_2048 from '../../assets/img/adaptive_background/sidebar2048-1.png';
import SidImg1_1920 from '../../assets/img/adaptive_background/sidebar1920-1.png';
import SidImg1_1440 from '../../assets/img/adaptive_background/sidebar1440-1.png';
import SidImg1_1366 from '../../assets/img/adaptive_background/sidebar1366-1.png';
import SidImg1_1280 from '../../assets/img/adaptive_background/sidebar1280-1.png';
import SidImg1_1024 from '../../assets/img/adaptive_background/sidebar1024-1.png';
import SidImg1_768 from '../../assets/img/adaptive_background/sidebar768-1.png';

import { COMMON, UNCOMMON, EPIC, RARE, LEGENDARY } from "../../Constants";

const InfoBlock = ({ width }) => {
    let sidImg = null;

    const [showInformation, setShowInformation] = useState(null);
    const [showFaq, setShowFaq] = useState(false);

    const activeItemHandler = (value) => {
        if (showInformation === value)
            return setShowInformation(null);

        setShowInformation(value);
    }

    const showInfo = (value) => {
        setShowFaq(value);
    }

    if (width <= 4096 && width > 3840 ) {
        sidImg = SidImg1_4096;
    } else if (width <= 3840 && width > 2580) {
        sidImg = SidImg1_3840;
    } else if (width <= 2580 && width > 2048) {
        sidImg = SidImg1_2560;
    } else if (width <= 2048 && width > 1920) {
        sidImg = SidImg1_2048;
    } else if (width <= 1920 && width > 1440) {
        sidImg = SidImg1_1920;
    } else if (width <= 1440 && width > 1366) {
        sidImg = SidImg1_1440;
    } else if (width <= 1366 && width > 1280) {
        sidImg = SidImg1_1366;
    } else if (width <= 1280 && width > 1024) {
        sidImg = SidImg1_1280;
    } else if (width <= 1024 && width > 768) {
        sidImg = SidImg1_1024;
    } else if (width <= 768 ) {
        sidImg = SidImg1_768;
    }

    const { stakedList, unstakedList } = useApp();

    const [totalDmpReward, setTotalDmpReward] = useState(0);
    const [stakedItemsInfo, setStakedItemsInfo] = useState({
        common: 0, uncommon: 0, rare: 0, epic: 0, legendary: 0,
    });
    const [unstakedItemsInfo, setUnstakedItemsInfo] = useState({
        common: 0, uncommon: 0, rare: 0, epic: 0, legendary: 0,
    });

    const countItemsByRarity = (items, rarityToFilter) => {
        return items.filter(({ data: { rarity } }) => rarity.toLowerCase() === rarityToFilter.toLowerCase()).length;
    }

    useEffect(() => {
        setStakedItemsInfo({
            common: countItemsByRarity(stakedList, COMMON),
            uncommon: countItemsByRarity(stakedList, UNCOMMON),
            rare: countItemsByRarity(stakedList, RARE),
            epic: countItemsByRarity(stakedList, EPIC),
            legendary: countItemsByRarity(stakedList, LEGENDARY),
        })

        setTotalDmpReward(stakedList.reduce((total, { miningInfo: { totalMiningReward } }) => total + totalMiningReward, 0));
    }, [stakedList]);

    useEffect(() => {
        setUnstakedItemsInfo({
            common: countItemsByRarity(unstakedList, COMMON),
            uncommon: countItemsByRarity(unstakedList, UNCOMMON),
            rare: countItemsByRarity(unstakedList, RARE),
            epic: countItemsByRarity(unstakedList, EPIC),
            legendary: countItemsByRarity(unstakedList, LEGENDARY),
        })
    }, [unstakedList]);

    return (
        <div className="sidebar_block info_block">
            <img src={sidImg} alt="" />
            <h3 className='block_title'>
                Staking Info
            </h3>

            <div className="block_information">
                <div
                    className={
                        classnames('information_item slide', {show: showInformation === 'showInformation'})
                    }
                    onClick={() => activeItemHandler('showInformation')}
                >
                    <div className="information_title">
                        Staked NFTs
                        <span
                            className='info_block'
                            onMouseOver={() => showInfo('staked')}
                            onMouseOut={() => showInfo(false)}
                        >
                            <InfoIcon />
                        </span>
                    </div>

                    <div className={classnames('info_popup', {show: showFaq === 'staked'})}>
                        Text information about staked NFTs. Text information about staked NFTs. Text information about staked NFTs.
                    </div>

                    <div className="information_value information__info">
                        <div className="value">
                            { stakedList.length }
                        </div>

                        <span className="postscript">
                        Equivalent in DM
                        </span>
                    </div>

                    <div className={classnames('information__content', {show: showInformation === 'showInformation'})}>
                        <div className="item">
                            <div className="title">{ COMMON }</div>
                            <div className="value">
                                { stakedItemsInfo.common }
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{ UNCOMMON }</div>
                            <div className="value">
                                { stakedItemsInfo.uncommon }
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{ RARE }</div>
                            <div className="value">
                                { stakedItemsInfo.rare }
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{ EPIC }</div>
                            <div className="value">
                                { stakedItemsInfo.epic }
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{ LEGENDARY }</div>
                            <div className="value">
                                { stakedItemsInfo.legendary }
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={classnames('information_item slide', {show: showInformation === 'Not Staked NFTs'})}
                    onClick={() => activeItemHandler('Not Staked NFTs')}
                >
                    <div className="information_title">
                        Not Staked NFTs
                        <span
                            className='info_block'
                            onMouseOver={() => showInfo('unstaked')}
                            onMouseOut={() => showInfo(false)}
                        >
                            <InfoIcon />
                        </span>
                    </div>

                    <div className={classnames('info_popup', {show: showFaq === 'unstaked'})}>
                        Text information about staked NFTs. Text information about staked NFTs. Text information about staked NFTs.
                    </div>
                    <div className="information_value information__info">
                        <div className="value">
                            { unstakedList.length }
                        </div>
                        <span className="postscript">
                        Equivalent in DM
                        </span>
                    </div>
                    <div className={classnames('information__content', {show: showInformation === 'Not Staked NFTs'})}>
                        <div className="item">
                            <div className="title">{ COMMON }</div>
                            <div className="value">
                                { unstakedItemsInfo.common }
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{ UNCOMMON }</div>
                            <div className="value">
                                { unstakedItemsInfo.uncommon }
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{ RARE }</div>
                            <div className="value">
                                { unstakedItemsInfo.rare }
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{ EPIC }</div>
                            <div className="value">
                                { unstakedItemsInfo.epic }
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{ LEGENDARY }</div>
                            <div className="value">
                                { unstakedItemsInfo.legendary }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="information_item">
                    <div className="information_title">
                        DMP Total Reward
                        <span
                            className='info_block'
                            onMouseOver={() => showInfo('dmp')}
                            onMouseOut={() => showInfo(false)}
                        >
                            <InfoIcon />
                        </span>
                    </div>
                    <div className={classnames('info_popup', {show: showFaq === 'dmp'})}>
                        Text information about staked NFTs. Text information about staked NFTs. Text information about staked NFTs.
                    </div>

                    <div className="information_value">
                        <div className="value">
                            { totalDmpReward }
                        </div>
                        <span className="postscript">
                        Equivalent in DM
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoBlock;