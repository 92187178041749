import { DEEPMINE_GAME, ATOMIC_ASSETS, STAKING_DURATION_1_DAY, STAKING_DURATION_FIXED } from "../Constants";
import { signTransaction, getTableData } from "../Helpers";

export const stakeItems = async ({ activeUser, items, fixed }) => {
    return await signTransaction({
        activeUser,
        account: ATOMIC_ASSETS,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: DEEPMINE_GAME,
            asset_ids: items,
            memo: fixed ? STAKING_DURATION_FIXED : STAKING_DURATION_1_DAY
        },
    })
};

export const unstakeItems = async ({ activeUser, items, fixed, days }) => {
    return await signTransaction({
        activeUser,
        account: DEEPMINE_GAME,
        action: 'unstake',
        data: {
            owner: activeUser.accountName,
            asset_ids: items,
            fixed: fixed,
            days: days
        }
    })
};

export const fetchStakingConfig = async () => {
    const config = await getTableData({
        contract: DEEPMINE_GAME,
        scope: DEEPMINE_GAME,
        table: 'stakingconf'
    });

    return config.map(item => {
        const [reward] = item.reward.split(' ');

        return { ...item, reward: Number(reward) }
    });
};
