import React from "react";
import { Route, Switch, Redirect } from 'react-router-dom';

import { useApp } from '../Data/AppContext';
import { MainPage, NFTStakingPage, MyStatsPage, RewardsPage, InformationPage } from "../Containers";

export const useRoutes = () => {
    const { isAuthenticated } = useApp();

    return (
        <Switch>
            <Route exact path='/'>
                <MainPage />
            </Route>

            <Route exact path='/NFTStaking'>
                <NFTStakingPage />
            </Route>

            { isAuthenticated && 
                <Route exact path='/MyStats'>
                    <MyStatsPage />
                </Route>
            }

            { isAuthenticated && 
                <Route exact path='/Rewards'>
                    <RewardsPage />
                </Route>
            }

            <Route exact path='/Information'>
                <InformationPage />
            </Route>

            <Redirect to='/' />
        </Switch>
    )
}
