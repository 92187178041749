import React from "react";
import classnames from 'classnames';

const SimpleButton = ({ children, handler, active, paint, hideStroke, opacity }) => {
    return (
        <button
            onClick={ handler }
            className={ classnames('btn', { 'active': active, 'paint': paint, 'hideStroke': hideStroke, 'opacity': opacity }) }
        >
            <span className='btn__wrap'>
                { children }
            </span>
        </button>
    )
}

export default SimpleButton;