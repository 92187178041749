import React from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as UnactiveHomeShelter } from '../../assets/img/menu/icon-home-unactive.svg';
import { ReactComponent as UnactiveNFTStaking } from '../../assets/img/menu/icon-staking-unactive.svg';
import { ReactComponent as UnactiveInformation } from '../../assets/img/menu/icon-inforormation-unactive.svg';
import { ReactComponent as UnactiveStats } from '../../assets/img/menu/icon-stats-unactive.svg';
// import { ReactComponent as UnactiveRewards } from '../../assets/img/menu/icon-stars-unactive.svg';

const MobileNavList = ({ isAuthenticated }) => {
    return (
        <ul className='mobile_menu__list'>
            <li>
                <NavLink className='mobile_menu__item' activeClassName='active_link' exact to='/'>
                    <UnactiveHomeShelter className='image'/>
                    <span>Home</span>
                </NavLink>
            </li>
            <li>
                <NavLink className='mobile_menu__item' activeClassName='active_link' to='/NFTStaking'>
                    <UnactiveNFTStaking className='image'/>
                    <span>NFT Staking</span>
                </NavLink>
            </li>
            {
                isAuthenticated &&
                <li>
                    <NavLink className='mobile_menu__item' activeClassName='active_link' to='/MyStats'>
                        <UnactiveStats className='image'/>
                        <span>My Operations</span>
                    </NavLink>
                </li>
            }
            {/* {
                isAuthenticated &&
                <li>
                    <NavLink className='mobile_menu__item' activeClassName='active_link' to='/Rewards'>
                        <UnactiveRewards className='image'/>
                        <span>Rewards</span>
                    </NavLink>
                </li>
            } */}
            <li>
                <NavLink className='mobile_menu__item' activeClassName='active_link' to='/Information'>
                    <UnactiveInformation className='image'/>
                    <span>Info</span>
                </NavLink>
            </li>
        </ul>
    )
}

export default MobileNavList;