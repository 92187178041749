import React from "react";
import { useApp } from "../../Data/AppContext";
import useWindowDimensions from "../../Hooks/WindowDimensions";
import LogoutBlock from "./LogoutBlock";
import InfoBlock from "./InfoBlock";
import EarnedBlock from "./EarnedBlock";

const Sidebar = () => {
    const { showInterface } = useApp();
    const { width } = useWindowDimensions();

    return (
        <div className='sidebar-wrapper staking_sidebar'>
            <LogoutBlock
                showInterface={showInterface}
            />

            <InfoBlock width={ width } />
            <EarnedBlock width={width} />
        </div>
    )
}

export default Sidebar;