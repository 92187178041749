import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";

import { UALContext } from "ual-reactjs-renderer";
import { useApp } from "../../Data/AppContext";

import { ReactComponent as WaxLogoImg } from '../../assets/img/header/header_wax.svg';
import { ReactComponent as LogoutImg } from '../../assets/img/icons/logout.svg';

const WaxLogo = () => {
    const { showModal, logout } = useContext(UALContext);
    const { userData, showInterface, isAuthenticated, userLogoutHandler, dmpBalance } = useApp();

    const userLogout = () => {
        logout();
        userLogoutHandler();
    }

    if (!isAuthenticated) {
        return (
            <div className='header_wax__wrapper'>
                <div className='header_wax' onClick={() => showModal()}>
                    <WaxLogoImg className='wax-logo'/>
                    Connect WAX Wallet
                </div>
            </div>
        )
    }

    return (
        <div className='header_wax__wrapper'>
            <div className='header_wax'>
                <WaxLogoImg className='wax-logo auth' />
                <NavLink to='/MyStats'>{ userData?.accountName }</NavLink>

                { showInterface && <span className="price"> { dmpBalance } </span> }

                <button
                    className='logout_btn'
                    onClick={userLogout}
                >
                    < LogoutImg /> Log out
                </button>
            </div>
        </div>
    )
}

export default WaxLogo;