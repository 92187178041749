import React from "react";
import { NavLink } from "react-router-dom";

import ChairImg from '../../assets/img/chair.png';
import ChairImg2 from '../../assets/img/chair2.png';

const ShowNFTButton = ({ scrollShelter }) => {
    return (
        <NavLink className='show-btn' to='/NFTStaking'>
            { !scrollShelter
                ? <img src={ ChairImg } alt="" />
                : <img src={ ChairImg2 } alt="" />
            }

            <span className='label'>NFT Staking</span>
        </NavLink> 
    );
}

export default ShowNFTButton;