import React from "react";
import classnames from 'classnames';

const ButtonLink = ({ children, to, active, onClick }) => {
    return (
        <a
            href={to}
            onClick={onClick}
            className={ classnames('btn', { 'active': active }) }
        >
            <span className='btn__wrap'>{ children }</span>
        </a>
    )
}

export default ButtonLink;