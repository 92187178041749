import React, { useContext } from "react";

import { UALContext } from "ual-reactjs-renderer";

import { ReactComponent as PurseImg } from '../../assets/img/icons/purse.svg';

import ButtonLink from "../Ui/ButtonLink/ButtonLink";

const Information = () => {
    const { showModal } = useContext(UALContext);

    return (
        <div className="unauthorized_content">
            <div className="unauthorized_wrapper">
                <div className="information__container">
                    <div className="usage_information">
                        <div className="usage_information__title">
                            <h2>To begin staking, you will need to complete the following steps:</h2>
                        </div>
                        <div className="usage_information__list">
                            <ul>
                                <li><a href='https://www.youtube.com/' className='marked_text'>Buy Deep Mine NFTs</a> on Rarible and return to DEEPMINE.WORLD.</li>
                                <li>Link your NFTs to use them on DEEPMINE.WORLD. To do this, click on the "Link All" button.</li>
                                <li>To stake your NFT, navigate over a selected NFT and click on the "Stake" button that shows up above the artwork.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="connect_wallet">
                      <PurseImg className="connect_wallet__icon" />
                      <h3 className="connect_wallet__title">WAX Wallet</h3>
                      <span className="connect_wallet__text">
                          To be able to stake NFTs you need<br/>to connect your wallet
                      </span>

                      <ButtonLink
                          active={true}
                          className='connect_wallet__btn'
                          onClick={() => showModal()}
                      >
                          connect wax wallet
                      </ButtonLink>
                </div>
            </div>
        </div>
    )
}

export default Information;