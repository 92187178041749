import React from "react";
import Countdown from "react-countdown";

import classnames from 'classnames';
import { useApp } from "../../Data/AppContext";

import SimpleButton from '../Ui/SimpleButton/SimpleButton';
import { PRE_LAUNCH_FINISH_TIMESTAMP, STAKING_DURATION_1_DAY } from "../../Constants";

const CardItem = ({ type, item, selectCard, selectedCards }) => {
    const { showPopupHandler } = useApp();

    function countdownRenderer({ days, hours, minutes, seconds, completed }) {
        if (completed)
            return <>0d 0h 0m</>;

        if (days)
            return <>{ days }d { hours }h { minutes }m</>;

        return <>{ hours }h { minutes }m {seconds}s</>;
    }

    if (type === 'nft') {
        return (
            <div
                onClick={() => {selectCard(item)}}
                className={
                    classnames('card-item', {'select': selectedCards.findIndex(({ asset_id }) => asset_id === item.asset_id) !== -1 })
                }
            >
                <div className="card-item__img">
                    <img src={`https://cloudflare-ipfs.com/ipfs/${item.data.img}`} alt="Card" />
                </div>
    
                <div
                    className={
                        classnames('unstaked', {'select': selectedCards.findIndex(({ asset_id }) => asset_id === item.asset_id) !== -1 })
                    }
                >
                    { !!!item.stakingInfo
                        ? <div className='wrapper_stake'>
                            <div className="title">
                                DMP Reward
                            </div>
                            <div className="list">
                                <div className="item">
                                    <div className="text">
                                        Daily Pool:
                                    </div>
                                    <div className="value">
                                        { item.miningInfo && item.miningInfo.dailyPool
                                            ? item.miningInfo.dailyPool.toFixed(4)
                                            : 0
                                        }
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text">
                                        Pre-launch Pool:
                                    </div>
                                    <div className="value">
                                        { item.miningInfo && item.miningInfo.preLaunchPool
                                            ? item.miningInfo.preLaunchPool.toFixed(4)
                                            : 0
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="btn_container">
                                <SimpleButton  handler={(event) => {
                                    event.stopPropagation();
                                    showPopupHandler('staking')
                                }}>
                                    Stake
                                </SimpleButton>
                            </div>
                        </div>
                        : <div className='wrapper_stake'>
                            <div className="title">
                                Claim NFTs?
                            </div>
                            <div className="list">
                                <div className="item">
                                    <div className="text">
                                        Time left to Unstake:
                                    </div>
                                    <div className="value">
                                        { item.stakingInfo.stakingDuration === STAKING_DURATION_1_DAY
                                            ? <Countdown
                                                date={ new Date(item.stakingInfo.time * 1000 + (60 * 60 * 24 * 1000)) }
                                                renderer={countdownRenderer}
                                            />
                                            : <Countdown
                                                date={PRE_LAUNCH_FINISH_TIMESTAMP}
                                                renderer={countdownRenderer}
                                            />
                                        }

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text">
                                        Time left for Daily Reward:
                                    </div>
                                    <div className="value">
                                        <Countdown
                                            date={ new Date(item.stakingInfo.time * 1000 + (60 * 60 * 24 * 1000)) }
                                            renderer={countdownRenderer}
                                        />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text">
                                        DMP total Reward:
                                    </div>
                                    <div className="value">
                                        { item.miningInfo.totalMiningReward }
                                    </div>
                                </div>
                            </div>
                            <div className="btn_container">
                                <SimpleButton handler={(event) => {
                                    event.stopPropagation();
                                    showPopupHandler('unstake');
                                } }>
                                    Unstake
                                </SimpleButton>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <div
            onClick={() => selectCard(item)}
            className={
                classnames('card-item', {'select': selectedCards.findIndex(({ asset_id }) => asset_id === item.asset_id) !== -1 })
            }
        >
            <div className="card-item__img">
                <img src="https://ipfs.atomichub.io/ipfs/QmRsP3ACHpWXLXnNDejb2qnjCa1YdeuStz98c5HoVjdyfa" alt="Card" />
            </div>

            <div
                className={
                    classnames('unstaked', {'select': selectedCards.findIndex(({ asset_id }) => asset_id === item.asset_id) !== -1 })
                }
            >
                { !!!item.stakingInfo
                    ? <div className='wrapper_stake'>
                        <div className="title">
                            Claim NFT?
                        </div>
                        <div className="list">
                            <div className="item">
                                <div className="text">
                                    DMP Cost
                                </div>
                                <div className="value">
                                    {/*TODO: remove hardcode (DMP Cost ?)*/}
                                    1231
                                </div>
                            </div>
                            <div className="item">
                                <div className="text">
                                    Staking Value
                                </div>
                                <div className="value">
                                    {/*TODO: remove hardcode (Staking Value ?)*/}
                                    1231
                                </div>
                            </div>
                        </div>
                        <div className="btn_container">
                            <SimpleButton active='true' handler={() => showPopupHandler('claim')}>
                                Claim
                            </SimpleButton>
                        </div>
                    </div>
                    : <div className='wrapper_stake'>
                        <div className="title">
                            Claim NFT?
                        </div>
                        <div className="list">
                            <div className="item">
                                <div className="text">
                                    {/*TODO: remove hardcode (DMP Cost ?)*/}
                                    1231
                                </div>
                                <div className="value">
                                    1231
                                </div>
                            </div>
                            <div className="item">
                                <div className="text">
                                    Staking Value
                                </div>
                                <div className="value">
                                    {/*TODO: remove hardcode (Staking Value ?)*/}
                                    1231
                                </div>
                            </div>
                        </div>
                        <div className="btn_container">
                            <SimpleButton active='true' handler={(event) => {
                                event.stopPropagation();
                                showPopupHandler('claim');
                            } }>
                                Claim
                            </SimpleButton>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default CardItem;