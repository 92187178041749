import React, { useEffect, useState } from "react";
import { useApp } from "../../Data/AppContext";
import classnames from 'classnames';

import { ReactComponent as InfoIcon } from '../../assets/img/icons/icon.svg';

import SimpleButton from "../Ui/SimpleButton/SimpleButton";

import { COMMON, EPIC, LEGENDARY, RARE, UNCOMMON } from "../../Constants";
import { countDmpToClaim } from "../../Helpers";

const StakingModal = () => {
    const { stakedList, unstakedList, stakingPopup, toggleStakingPopupHandler, showPopupHandler } = useApp();

    const [showInformation, setShowInformation] = useState(null);
    const [showFaq, setShowFaq] = useState(false);

    const [totalDmpReward, setTotalDmpReward] = useState(0);
    const [stakedItemsInfo, setStakedItemsInfo] = useState({
        common: 0, uncommon: 0, rare: 0, epic: 0, legendary: 0,
    });
    const [unstakedItemsInfo, setUnstakedItemsInfo] = useState({
        common: 0, uncommon: 0, rare: 0, epic: 0, legendary: 0,
    });

    const countItemsByRarity = (items, rarityToFilter) => {
        return items.filter(({ data: { rarity } }) => rarity.toLowerCase() === rarityToFilter.toLowerCase()).length;
    }

    useEffect(() => {
        setStakedItemsInfo({
            common: countItemsByRarity(stakedList, COMMON),
            uncommon: countItemsByRarity(stakedList, UNCOMMON),
            rare: countItemsByRarity(stakedList, RARE),
            epic: countItemsByRarity(stakedList, EPIC),
            legendary: countItemsByRarity(stakedList, LEGENDARY),
        });

        setTotalDmpReward(stakedList.reduce((total, { miningInfo: { totalMiningReward } }) => total + totalMiningReward, 0));
    }, [stakedList]);

    useEffect(() => {
        setUnstakedItemsInfo({
            common: countItemsByRarity(unstakedList, COMMON),
            uncommon: countItemsByRarity(unstakedList, UNCOMMON),
            rare: countItemsByRarity(unstakedList, RARE),
            epic: countItemsByRarity(unstakedList, EPIC),
            legendary: countItemsByRarity(unstakedList, LEGENDARY),
        })
    }, [unstakedList]);

    const activeItemHandler = (value) => {
        if (showInformation === value)
            return setShowInformation(null);

        showInfo(false);
        setShowInformation(value);
    }

    const showInfo = (value) => {
        if (showInformation !== null)
            setShowInformation(null);

        setShowFaq(value);
    }

    const dmpToStake = countDmpToClaim({ stakedItems: stakedList });

    return (
        <div
            className={ classnames('staking_popup', {active: stakingPopup}) }
            onClick={() => showInfo(false) }
        >
            <div className="close_modal">
                <button onClick={() => toggleStakingPopupHandler(false)}>
                    Close <span>x</span>
                </button>
            </div>

            <div className="staking_container">
                <div className="staking_info">
                    <h3 className='block_title'>
                        Staking Info
                    </h3>
                    <div className="block_information">
                    <div
                        className={
                            classnames('information_item slide', {show: showInformation === 'showInformation'})
                        }
                        onClick={() => activeItemHandler('showInformation')}
                    >
                        <div className="information_title">
                            Staked NFTs
                            <span
                                className='info_block'
                                onClick={(event) => {
                                    event.stopPropagation();
                                    showInfo('staked');
                                }}
                            >
                                <InfoIcon />
                            </span>

                            <div className={classnames('info_popup', {show: showFaq === 'staked'})}>
                                Text information about staked NFTs. Text information about staked NFTs. Text information about staked NFTs.
                            </div>
                        </div>
                        <div className="information_value information__info">
                            <div className="value">
                                { stakedList.length }
                            </div>
                            <span className="postscript">
                            Equivalent in DM
                            </span>
                        </div>
                        <div className={classnames('information__content', {show: showInformation === 'showInformation'})}>
                        <div className="item">
                            <div className="title">{ COMMON }</div>
                            <div className="value">{ stakedItemsInfo.common }</div>
                        </div>
                        <div className="item">
                            <div className="title">{ UNCOMMON }</div>
                            <div className="value">{ stakedItemsInfo.uncommon }</div>
                        </div>
                        <div className="item">
                            <div className="title">{ RARE }</div>
                            <div className="value">{ stakedItemsInfo.rare }</div>
                        </div>
                        <div className="item">
                            <div className="title">{ EPIC }</div>
                            <div className="value">{ stakedItemsInfo.epic }</div>
                        </div>
                        <div className="item">
                            <div className="title">{ LEGENDARY }</div>
                            <div className="value">{ stakedItemsInfo.legendary }</div>
                        </div>
                    </div>
                    </div>
                    <div
                        className={classnames('information_item slide', {show: showInformation === 'Not Staked NFTs'})}
                        onClick={() => activeItemHandler('Not Staked NFTs')}
                    >
                        <div className="information_title">
                            Not Staked NFTs
                            <span
                                className='info_block'
                                onClick={(event) => {
                                    event.stopPropagation();
                                    showInfo('unstaked');
                                }}
                            >
                                <InfoIcon />
                            </span>
                            <div className={classnames('info_popup', {show: showFaq === 'unstaked'})}>
                                Text information about staked NFTs. Text information about staked NFTs. Text information about staked NFTs.
                            </div>
                        </div>
                        <div className="information_value information__info">
                            <div className="value">
                                { unstakedList.length }
                            </div>
                            <span className="postscript">
                            Equivalent in DM
                            </span>
                        </div>
                        <div className={classnames('information__content', {show: showInformation === 'Not Staked NFTs'})}>
                            <div className="item">
                                <div className="title">{ COMMON }</div>
                                <div className="value">{ unstakedItemsInfo.common }</div>
                            </div>
                            <div className="item">
                                <div className="title">{ UNCOMMON }</div>
                                <div className="value">{ unstakedItemsInfo.uncommon }</div>
                            </div>
                            <div className="item">
                                <div className="title">{ RARE }</div>
                                <div className="value">{ unstakedItemsInfo.rare }</div>
                            </div>
                            <div className="item">
                                <div className="title">{ EPIC }</div>
                                <div className="value">{ unstakedItemsInfo.epic }</div>
                            </div>
                            <div className="item">
                                <div className="title">{ LEGENDARY }</div>
                                <div className="value">{ unstakedItemsInfo.legendary }</div>
                            </div>
                        </div>
                    </div>
                    <div className="information_item">
                        <div className="information_title">
                            DMP Total Reward
                            <span className='info_block' onClick={(event) => {
                                event.stopPropagation();
                                showInfo('dmp');
                            }}>
                                <InfoIcon />
                            </span>
                            <div className={classnames('info_popup', {show: showFaq === 'dmp'})}>
                                Text information about staked NFTs. Text information about staked NFTs. Text information about staked NFTs.
                            </div>
                        </div>
                        <div className="information_value">
                            <div className="value">
                                { totalDmpReward }
                            </div>
                            <span className="postscript">
                            Equivalent in DM
                            </span>
                        </div>
                    </div>
                </div>
                </div>
                <div className="earned_rewards">
                    <h3 className='block_title'>
                        DMP to claim
                    </h3>
                    <div className="block_information">
                    <div className="information_item">
                        <div className="information_value">
                            <div className="value">
                                { dmpToStake }
                            </div>
                            <span className="postscript">
                            Equivalent in DM
                            </span>
                        </div>
                    </div>
                    </div>
                    <div className="button_block">
                        <SimpleButton active={true} handler={() => showPopupHandler('claim')}>
                            Claim
                        </SimpleButton>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default StakingModal;