import React from "react";
import classnames from 'classnames';

const TransactionItem = ({ id, type, date, amount, status, txId, minTxId }) => {
    const transactionHistoryBloks = () =>{
        window.open(`https://wax-test.bloks.io/transaction/${txId}`, '_blank'); // for Testnet
    }

    return (
        <tr className="transaction_table__line">
            <td className='table_id'>
                { id }
            </td>
            <td>
                { type }
            </td>
            <td>
                { date }
            </td>
            <td>
                { amount ? `${amount} NFT` : '' }
            </td>
            <td className={classnames({success: status === 'Successful', error: status === 'Failed', progress: status === 'In progress'})}>
                { status }
            </td>
            <td onClick={transactionHistoryBloks} className="bloks-history">
                { minTxId }
            </td>
        </tr>
    )
}

export default TransactionItem;