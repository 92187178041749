import React from 'react';
import SimpleButton from '../Ui/SimpleButton/SimpleButton';

const DMPModal = () => {
    return (
        <div className='content_block DMP_modal'>
            <div className="text">
            Please, change your choice <br/>
            or <br />
            earn more DMP
            </div>
            <div className="button_blocks">
                <SimpleButton >
                    Ok
                </SimpleButton>
            </div>
        </div>
    )
}

export default DMPModal;