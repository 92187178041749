import React from 'react';

import classnames from 'classnames';
import { useApp } from '../../Data/AppContext';
import useWindowDimensions from '../../Hooks/WindowDimensions';

import LogoImg from './Logo.jsx';
import WaxLogo from './WaxLogo.jsx';

import HeaderBg4096 from '../../assets/img/adaptive_background/header4096.png';
import HeaderBg3840 from '../../assets/img/adaptive_background/header-3840.png';
import HeaderBg2560 from '../../assets/img/adaptive_background/header2560.png';
import HeaderBg2048 from '../../assets/img/adaptive_background/header2048.png';
import HeaderBg1920 from '../../assets/img/adaptive_background/header1920.png';
import HeaderBg1440 from '../../assets/img/adaptive_background/header1440.png';
import HeaderBg1366 from '../../assets/img/adaptive_background/header1366.png';
import HeaderBg1280 from '../../assets/img/adaptive_background/header1280.png';
import HeaderBg1024 from '../../assets/img/adaptive_background/header1024.png';
import HeaderBg768 from '../../assets/img/adaptive_background/header768.png';

const Header = () => {
    const { showInterface, toggleInterfaceHandler } = useApp();
    const { width } = useWindowDimensions();
    let headerImg = null;
    
    if (width <= 4096 && width > 3840 ) {
        headerImg = HeaderBg4096;
    } else if (width <= 3840 && width > 2580) {
        headerImg = HeaderBg3840;
    } else if (width <= 2580 && width > 2048) {
        headerImg = HeaderBg2560;
    } else if (width <= 2048 && width > 1920) {
        headerImg = HeaderBg2048;
    } else if (width <= 1920 && width > 1440) {
        headerImg = HeaderBg1920;
    } else if (width <= 1440 && width > 1366) {
        headerImg = HeaderBg1440;
    } else if (width <= 1366 && width > 1280) {
        headerImg = HeaderBg1366;
    } else if (width <= 1280 && width > 1024) {
        headerImg = HeaderBg1280;
    } else if (width <= 1024 && width > 768) {
        headerImg = HeaderBg1024;
    } else if (width <= 768 ) {
        headerImg = HeaderBg768;
    }

    return (
        <header className={classnames('header', { 'show': showInterface })}>
            <img className="header__bg" src={ headerImg } alt="" />
            <div className='header__container'>
                <LogoImg
                    toggleInterface={toggleInterfaceHandler}
                    showInterface={showInterface}
                    width={width}
                />

                <WaxLogo />
            </div>
        </header>
    )
}

export default Header;