import React, { useContext, useEffect, useState } from "react";

import { UALContext } from "ual-reactjs-renderer";
import { toast } from "react-toastify";
import { useApp } from "../../Data/AppContext";

import SimpleButton from "../Ui/SimpleButton/SimpleButton";

import { claimMiningReward, fetchDmpBalance } from "../../Services";

const ClaimModal = ({ hidePopupHandler }) => {
    const { activeUser } = useContext(UALContext);

    const { stakedList, setDmpBalance, setUserStakedItems } = useApp();

    const [longestStakedTime, setLongestStakedTime] = useState('');
    const [totalDmpReward, setTotalDmpReward] = useState(0);

    const handleClaim = () => {
        claimMiningReward({ activeUser })
            .then(() => {
                fetchDmpBalance({ account: activeUser.accountName })
                    .then(balance => setDmpBalance(balance))
                    .catch(e => console.log(e));

                setUserStakedItems(stakedList.map(item => {
                    return {
                        ...item,
                        stakingInfo: {
                            ...item.stakingInfo,
                            time: new Date().getTime() / 1000
                        }
                    }
                }))

                toast.success('Claimed');
            })
            .catch(e => toast.error(e))
            .finally(() => hidePopupHandler());
    };

    useEffect(() => {
        const time = stakedList
            .sort((item1, item2) => item2.stakingInfo.time - item1.stakingInfo.time)
            .find(item => !!item)
            ?.stakingInfo?.time;

        const date = new Date(time ? time * 1000 : 0);

        setLongestStakedTime(time ? `${date.getFullYear()}.${date.getMonth()}.${date.getDate()}` : '');
        setTotalDmpReward(stakedList.reduce((total, { miningInfo: { totalMiningReward } }) => total + totalMiningReward, 0));
    }, [stakedList]);

    return (
        <div className='content_block claim_modal'>
            <div className="claim">
                <div className="claim__title">
                    Claim
                </div>
                <div className="claim__text">
                    Claim text
                </div>
            </div>

            <div className="information">
                <div className="line">
                    <div className="name">
                        Staked on
                    </div>
                    <div className="value">
                        { longestStakedTime }
                    </div>
                </div>
                <div className="line">
                    <div className="name">
                        DMP earned
                    </div>
                    <div className="value">
                        { totalDmpReward }
                    </div>
                </div>
                {/*TODO: remove hardcode, what is Unstake Penalty?*/}
                {/*<div className="line">*/}
                {/*    <div className="name">*/}
                {/*        Unstake Penalty*/}
                {/*    </div>*/}
                {/*    <div className="value">*/}
                {/*        100*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*TODO: remove hardcode, what is Earned Reward*/}
                {/*<div className="line">*/}
                {/*    <div className="name">*/}
                {/*        Earned Reward*/}
                {/*    </div>*/}
                {/*    <div className="value">*/}
                {/*        0.002*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="button_blocks">
                <SimpleButton handler={hidePopupHandler}>
                    Cancel
                </SimpleButton>

                <SimpleButton handler={handleClaim}>
                    Claim
                </SimpleButton>
            </div>
        </div>
    )
}

export default ClaimModal;