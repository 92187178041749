import React from 'react';
import ReactDOM from 'react-dom';

import './assets/scss/main.scss';

import { App } from './Containers';

import { UALProvider } from "ual-reactjs-renderer";
import { waxChain, waxAuthenticators } from "./Config/wax.config";

ReactDOM.render(
  <React.StrictMode>
    <UALProvider
      chains={[waxChain]}
      authenticators={waxAuthenticators}
      appName={'DeepMine'}
    >
        <App />
    </UALProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
