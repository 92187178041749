import React from "react";
import { useApp } from "../../Data/AppContext";

const MobileStatusBarStaking = ({ type }) => {
    const { unstakedList, stakedList, dmpBalance } = useApp();

    if (type === 'rewards') {
        return (
            <div className='status-bar__mobile rewards'>
                <div className="status-bar__container">
                    <div className="status_item">
                        <div className="title">DMP Total</div>
                        { dmpBalance}
                    </div>
                    <div className="status_item">
                        <div className="title">Claimed NFT Reward</div>
                        {/*TODO: remove hardcode*/}
                        23423423
                    </div>
                    <div className="status_item">
                        <div className="title">Reward NFT Collection</div>
                        {/*TODO: remove hardcode*/}
                        2342
                    </div>
                </div>
            </div>
        ) 
    }

    return (
        <div className='status-bar__mobile nft'>
            <div className="status-bar__container">
                <div className="left__block">
                    <div className="status_item">
                        <div className="title">Liquid DMP</div>
                        {/*TODO: remove hardcode*/}
                        <div className="value">2444</div>
                    </div>
                    <div className="status_item">
                        <div className="title">DMP Earned</div>
                        {/*TODO: remove hardcode*/}
                        <div className="value">2444</div>
                    </div>
                </div>
                <div className="right__block">
                    <div className="status_item">
                        <div className="title">Staked NFTs</div>
                        <div className="value">{ stakedList.length }</div>
                    </div>
                    <div className="status_item">
                        <div className="title">Not Staked NFTs</div>
                        <div className="value">{ unstakedList.length }</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileStatusBarStaking;