import React from "react";
import RewardsInfo from "./RewardsInfo";


const RewardSidebar = () => {
    return (
        <div className='sidebar-wrapper rewards_sidebar'>
            <RewardsInfo />
        </div>
    )
}

export default RewardSidebar;