import React from "react";
import classnames from "classnames";

const FilterSelector = ({ active, selectedValue, items, selectorToggle, selectValue, label}) => {
    return (
        <div className={classnames('selector__toggle', {open: active})}>
            <div
                className='selected_value'
                onClick={() => selectorToggle(!active)}
            >
                <span>{ selectedValue !== '' ? selectedValue : label }</span>
            </div>

                <div className={classnames('selector__list', {show: active})}>
                    { items.map((item, index) =>
                        <div
                            key={index}
                            className='item'
                            data-time={item}
                            onClick={ ()=>selectValue(item) }
                        >
                            { item }
                        </div>
                    ) }
            </div>
        </div>
    )
}

export default FilterSelector;