import { createContext, useContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import {
    SHOW_POPUP,
    HIDE_POPUP,
    DARKEN_SCREEN,
    LIGHTEN_SCREEN,
    USER_LOGIN,
    SET_USER_DATA,
    TOGGLE_INTERFACE,
    SHOW_SIDEBAR,
    USER_LOGOUT,
    TOGGLE_BLUR,
    TOGGLE_SHELTER,
    SCROLL_SHELTER,
    TOGGLE_STAKING_POPUP,
    TOGGLE_REWARDS_POPUP,
    TOGGLE_RARE_FILTER,
    TOGGLE_TERM_FILTER,
    USER_STAKED_ITEMS,
    ADD_ITEMS_TO_USER_STAKED_ITEMS,
    REMOVE_ITEMS_FROM_USER_STAKED_ITEMS,
    USER_NOT_STAKED_ITEMS,
    ADD_ITEMS_TO_USER_NOT_STAKED_ITEMS,
    REMOVE_ITEMS_FROM_USER_NOT_STAKED_ITEMS,
    USER_WAX_BALANCE,
    USER_DMP_BALANCE,
    SET_STAKING_CONFIG,
    TRANSACTION_INFO,
    SELECTED_ITEMS_FOR_STAKE,
    SELECTED_ITEMS_FOR_UNSTAKE

} from './AppActionTypes';

const AppContext = createContext();

export const useApp = () => {
    return useContext(AppContext);
}

// Todo:: тут в основном вся логика по взаимодействию с интерфейсом и тестовые данные, которые потом поменять на данные из запросов.
const initialState = {
    isAuthenticated: false,
    userData: null,
    showPopup: false,
    popupType: null,
    shadow: false,
    showInterface: true,
    sidebar: false,
    blur: false,
    shelter: false,
    scrollShelter: false,
    stakingPopup: false,
    rewardsPopup: false,

    nftList: [
        { id: 0, stake: false, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 1, stake: false, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 2, stake: false, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 3, stake: false, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 4, stake: false, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 5, stake: false, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 6, stake: false, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
    ],

    stakedList: [],
    stakedListFetched: false,
    unstakedList: [],
    unstakedListFetched: false,

    selectedItemsForStake: [],
    selectedItemsForUnstake: [],

    rewardsList: [
        { id: 0, stake: false, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 1, stake: true, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 2, stake: true, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 3, stake: true, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 4, stake: true, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 5, stake: true, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 6, stake: true, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 7, stake: true, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
        { id: 8, stake: true, img: null, information: [
            { name: 'Staking Value', value: 1231 },
            { name: 'Staked date', value: '21.10.21' },
            { name: 'Unstaked Date', value: '-' },
            { name: 'DMP earned', value: 1231 },
            { name: 'Unstaked Penalty', value: 100 },
            { name: 'Earned Reward', value: 0.002},
        ]},
    ],

    stakingConfig: [],
    stakingConfigFetched: false,

    waxBalance: '0 WAX',
    waxBalanceFetched: false,
    dmpBalance: '0 DMP',
    dmpBalanceFetched: false,

    toggleRareFilter: false,
    toggleTermFilter: false,

    transactionInfoFetched: false,
    transactionsInfo: [],
}

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);
    const showPopupHandler = value => dispatch({ type: SHOW_POPUP, value });
    const hidePopupHandler = () => dispatch({ type: HIDE_POPUP });

    const darkenScreenHandler = () => dispatch({ type: DARKEN_SCREEN });
    const lightenScreenHandler = () => dispatch({ type: LIGHTEN_SCREEN });

    const userLoginHandler = () => dispatch({ type: USER_LOGIN });
    const setUserDataHandler = data => dispatch({ type: SET_USER_DATA, data});
    const userLogoutHandler = () => dispatch({ type: USER_LOGOUT });

    const setUserStakedItems = value => dispatch({ type: USER_STAKED_ITEMS, value });
    const addItemsToUserStakedItems = value => dispatch({ type: ADD_ITEMS_TO_USER_STAKED_ITEMS, value });
    const removeItemsFromUserStakedItems = value => dispatch({ type: REMOVE_ITEMS_FROM_USER_STAKED_ITEMS, value });

    const setUserNotStakedItems = value => dispatch({ type: USER_NOT_STAKED_ITEMS, value });
    const addItemsToUserNotStakedItems = value => dispatch({ type: ADD_ITEMS_TO_USER_NOT_STAKED_ITEMS, value });
    const removeItemsFromUserNotStakedItems = value => dispatch({ type: REMOVE_ITEMS_FROM_USER_NOT_STAKED_ITEMS, value });

    const setSelectedItemsForStake = value => dispatch({ type: SELECTED_ITEMS_FOR_STAKE, value });
    const setSelectedItemsForUnstake = value => dispatch({ type: SELECTED_ITEMS_FOR_UNSTAKE, value });

    const toggleInterfaceHandler = value => dispatch({ type: TOGGLE_INTERFACE, value });

    const toggleSidebarHadler = value => dispatch({ type: SHOW_SIDEBAR, value });

    const toggleBlurHandler = value => dispatch({type: TOGGLE_BLUR, value});

    const toggleShelterHandler = value => dispatch({ type: TOGGLE_SHELTER, value });
    const scrollShelterHandler = value => dispatch({ type: SCROLL_SHELTER, value });

    const toggleStakingPopupHandler = value => dispatch({ type: TOGGLE_STAKING_POPUP, value });
    const toggleRewardsPopupHandler = value => dispatch({ type: TOGGLE_REWARDS_POPUP, value });

    const toggleRareFilterHandler = value => dispatch({ type: TOGGLE_RARE_FILTER, value });
    const toggleTermFilterHandler = value => dispatch({ type: TOGGLE_TERM_FILTER, value });

    const setWaxBalance = value => dispatch({ type: USER_WAX_BALANCE, value });
    const setDmpBalance = value => dispatch({ type: USER_DMP_BALANCE, value });

    const setTransactionInfo = value => dispatch({ type: TRANSACTION_INFO, value });

    const setStakingConfig = value => dispatch({ type: SET_STAKING_CONFIG, value });

    return (
        <AppContext.Provider value={{
            userData: state.userData,

            showInterface: state.showInterface,
            shadow: state.shadow,
            isAuthenticated: state.isAuthenticated,
            sidebar: state.sidebar,
            showPopup: state.showPopup,
            popupType: state.popupType,

            nftList: state.nftList,
            stakedList: state.stakedList,
            unstakedList: state.unstakedList,
            rewardsList: state.rewardsList,

            blur: state.blur,
            noNft: state.noNft,
            shelter: state.shelter,
            scrollShelter: state.scrollShelter,
            stakingPopup: state.stakingPopup,
            rewardsPopup: state.rewardsPopup,
            toggleRareFilter: state.toggleRareFilter,
            toggleTermFilter: state.toggleTermFilter,

            stakedListFetched: state.stakedListFetched,
            unstakedListFetched: state.unstakedListFetched,

            waxBalance: state.waxBalance,
            waxBalanceFetched: state.waxBalanceFetched,
            dmpBalance: state.dmpBalance,
            dmpBalanceFetched: state.dmpBalanceFetched,

            transactionsInfo: state.transactionsInfo,
            transactionInfoFetched: state.transactionInfoFetched,

            selectedItemsForStake: state.selectedItemsForStake,
            selectedItemsForUnstake: state.selectedItemsForUnstake,

            stakingConfig: state.stakingConfig,
            stakingConfigFetched: state.stakingConfigFetched,

            showPopupHandler,
            hidePopupHandler,
            darkenScreenHandler,
            lightenScreenHandler,
            userLoginHandler,
            userLogoutHandler,
            setUserNotStakedItems,
            addItemsToUserNotStakedItems,
            removeItemsFromUserNotStakedItems,
            setUserStakedItems,
            addItemsToUserStakedItems,
            removeItemsFromUserStakedItems,
            setUserDataHandler,
            toggleInterfaceHandler,
            toggleSidebarHadler,
            toggleBlurHandler,
            toggleShelterHandler,
            scrollShelterHandler,
            toggleStakingPopupHandler,
            toggleRewardsPopupHandler,
            toggleRareFilterHandler,
            toggleTermFilterHandler,
            setWaxBalance,
            setDmpBalance,
            setTransactionInfo,
            setStakingConfig,
            setSelectedItemsForStake,
            setSelectedItemsForUnstake
        }}>
            { children }
        </AppContext.Provider>
    )
}