import React from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as HomeShelter } from '../../assets/img/menu/icon-home.svg';
import { ReactComponent as NFTStaking } from '../../assets/img/menu/icon-staking.svg';
import { ReactComponent as Information } from '../../assets/img/menu/icon-inforormation.svg';
import { ReactComponent as Stats } from '../../assets/img/menu/icon-stats.svg';
// import { ReactComponent as Rewards } from '../../assets/img/menu/icon-stars.svg';

const NavList = ({ isAuthenticated }) => {
    return (
        <ul className='menu-list'>
            <li className='menu-list__items'>
                <NavLink className='menu-list__items__link' activeClassName='active_link' exact to='/'>
                    <div className="background_container" />
                    <div className="img_container">
                        < HomeShelter className='menu-list__items__img'/>
                    </div>
                    Shelter Lobby
                </NavLink>
            </li>
            <li className='menu-list__items'>
                <NavLink className='menu-list__items__link' activeClassName='active_link' to='/NFTStaking'>
                <div className="background_container" />
                    <div className="img_container">
                        < NFTStaking className='menu-list__items__img'/>
                    </div>
                    NFT Staking
                </NavLink>
            </li>
            {
                isAuthenticated &&
                <li className='menu-list__items'>
                    <NavLink className='menu-list__items__link' activeClassName='active_link' to='/MyStats'>
                    <div className="background_container" />
                        <div className="img_container">
                            < Stats className='menu-list__items__img'/>
                        </div>
                        My Operations
                    </NavLink>
                </li>
            }
            {/* {
                isAuthenticated &&
                <li className='menu-list__items'>
                    <NavLink className='menu-list__items__link' activeClassName='active_link' to='/Rewards'>
                    <div className="background_container"></div>
                        <div className="img_container">
                            < Rewards className='menu-list__items__img'/>
                        </div>
                        Rewards
                    </NavLink>
                </li>
            } */}

            <li className='menu-list__items'>
                <NavLink className='menu-list__items__link' activeClassName='active_link' to='/Information'>
                <div className="background_container" />
                    <div className="img_container">
                        < Information className='menu-list__items__img'/>
                    </div>
                    Information
                </NavLink>
            </li>
        </ul>
    )
}

export default NavList;