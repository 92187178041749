import { DEEPMINE_GAME } from "../Constants";
import { signTransaction } from "../Helpers";

export const claimMiningReward = async ({ activeUser }) => {
    return await signTransaction({
        activeUser,
        account: DEEPMINE_GAME,
        action: 'claim',
        data: { owner: activeUser.accountName }
    });
};
