export const countDmpToClaim = ({ stakedItems }) => {
    if (!stakedItems || !stakedItems.length)
        return 0;

    let dmpToClaim = 0;
    for (const { stakingInfo: { time, rate } } of stakedItems) {
        const startDate = new Date(time);
        const now = new Date(new Date().getTime() / 1000);

        const totalDays = Math.floor((now - startDate + 1) / 8.64e7);

        dmpToClaim += (totalDays * rate);
    }

    return dmpToClaim;
};
