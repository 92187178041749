import React from "react";
import { NavLink } from "react-router-dom";

import classnames from 'classnames';
import { useApp } from "../../Data/AppContext";
import { Alert, LoginButton, ContentContainer, ShowNFTButton } from "../../Components";
// import ShowRewardsButton from "../../Components/ShowRewardsButton/ShowRewardsButton";
// import RightScroll from "../../Components/ScrollPage/RightScroll";
// import LeftScroll from "../../Components/ScrollPage/LeftScroll";

import { countDmpToClaim } from "../../Helpers";

const MainPage = () => {
    const {
        stakedList,
        showPopupHandler, 
        userData, 
        isAuthenticated, 
        showInterface, 
        shelter, 
        scrollShelter, 
        // scrollShelterHandler 
    } = useApp();

    if (!userData) {
        return (
            <ContentContainer>
                <div className={classnames('main-screen', { 'main-screen__hide-int': !showInterface })}>
                < Alert 
                    type={'login'} 
                    caption={'deepmine citizens only'} 
                    text={'Please pass the identification.'}
                />
                < LoginButton type={'auth'} showModal={ showPopupHandler } />
                </div>
            </ContentContainer>
        )
    }

    if (userData && userData.butNFT && !isAuthenticated) {
        return (
            <ContentContainer>
                <div className={classnames('main-screen', { 'main-screen__hide-int': !showInterface})}>
                    < Alert 
                        type={'warning'} 
                        caption={'no deepmine nfts founded'} 
                        text={'Access restricted'}
                        subtext={'Please, buy DeepMine NFTs'}
                        img={true}
                    />
                    < LoginButton type={'warning'} showModal={ showPopupHandler }/> 
                </div>
            </ContentContainer>
        )
    }

    if (userData && !isAuthenticated) {
        return (
            <ContentContainer>
                <div className={classnames('main-screen', { 'main-screen__hide-int': !showInterface})}>
                    < Alert 
                        type={'success'} 
                        caption={'welcome, citizen!'} 
                        text={'Access granted'}
                    />
                    < LoginButton type={'success'}/> 
                </div>
            </ContentContainer>
        )
    }

    if (userData && isAuthenticated) {
        const dmpToClaim = countDmpToClaim({ stakedItems: stakedList });

        return (
            <ContentContainer>
                <div className={
                    classnames('main-screen', {
                        'main-screen__hide-int': !showInterface,
                        'shelter': shelter,
                        'scroll__shelter': scrollShelter
                    })
                }>
                    {
                        // !scrollShelter && 
                        <NavLink to='/MyStats'>
                            < Alert 
                                type={'auth'} 
                                caption={'DMP'}
                                text={dmpToClaim}
                                label={true}
                            />
                        </NavLink>
                    }
                    
                    <ShowNFTButton scrollShelter = {scrollShelter}/>
                    {/* < ShowRewardsButton scrollShelter = {scrollShelter}/> */}
                    {/* < LeftScroll scrollShelterHandler = { scrollShelterHandler } scrollShelter = { scrollShelter } />
                    < RightScroll scrollShelterHandler = { scrollShelterHandler } scrollShelter = { scrollShelter } /> */}
                </div>
            </ContentContainer>
        )
    }
}

export default MainPage;