import axios from "axios";

import {
    ATOMIC_ASSETS_API,
    DEEPMINE_GAME,
    EOSIO_TOKEN,
    MINING_MULTIPLIER,
    STAKING_DURATION_1_DAY,
    STAKING_DURATION_FIXED
} from "../Constants";
import { fetchRows, rpc } from '../Helpers';

export const fetchWaxBalance = async ({ account }) => {
    const { rows } = await fetchRows({
        contract: EOSIO_TOKEN,
        scope: account,
        table: "accounts"
    });

    if (!rows[0])
        return `0 WAX`;

    const [value, currency] = rows[0].balance.split(' ');

    return `${Number(value).toFixed(1)} ${currency}`;
};

export const fetchDmpBalance = async ({ account }) => {
    const { rows } = await fetchRows({
        contract: DEEPMINE_GAME,
        scope: account,
        table: "accounts",
        limit: 1
    });

    if (!rows[0])
        return `0 DMP`;

    const [value, currency] = rows[0].balance.split(' ');

    return `${Number(value).toFixed(1)} ${currency}`;
};

export const fetchNotStakedItems = async ({ account, stakingConfig }) => {
    const {
        data: { data }
    } = await axios.get(`${ATOMIC_ASSETS_API}/assets?collection_name=${DEEPMINE_GAME}&owner=${account}&page=1&limit=1000`);

    return data.map(item => {
        const rewardFromConfig = stakingConfig.find(({ template_id }) => +template_id === +item.template.template_id);
        const dailyPool = rewardFromConfig.reward;

        return { ...item, miningInfo: { dailyPool, preLaunchPool: dailyPool * MINING_MULTIPLIER } };
    });
};

const countTotalMiningReward = ({ startTimestamp, rewardPerDay }) => {
    const startDate = new Date(startTimestamp);
    const now = new Date(new Date().getTime() / 1000);

    const totalDays = Math.floor((now - startDate + 1) / 8.64e7);

    return totalDays * Number(rewardPerDay);
};

export const fetchStakedItems = async ({ account }) => {
    const { rows } = await fetchRows({
        contract: DEEPMINE_GAME,
        scope: account,
        table: "stake",
        limit: 1
    });

    if (!rows[0])
        return [];

    const stakedItems = [...rows[0].staked_nfts_Fixed, ...rows[0].staked_nfts_1D];

    const {
        data: { data }
    } = await axios.get(`${ATOMIC_ASSETS_API}/assets?ids=${stakedItems.map(({ asset_id }) => asset_id).join(',')}&limit=1000&page=1`);

    return data.map(item => {
        const stakingInfo = stakedItems.find(({ asset_id }) => asset_id === item.asset_id);
        const isNftStakedForOneDay = rows[0].staked_nfts_1D.find(({ asset_id }) => asset_id === item.asset_id);

        const stakingDuration = isNftStakedForOneDay ? STAKING_DURATION_1_DAY : STAKING_DURATION_FIXED;

        const totalMiningReward = countTotalMiningReward({
            startTimestamp: stakingInfo.time, rewardPerDay: stakingInfo.rate
        });

        return {
            ...item,
            stakingInfo: {
                ...stakingInfo,
                stakingDuration,
            },
            miningInfo: {
                totalMiningReward,
            }
        };
    });
}

export const fetchTransactionHistory = async ({ account }) => {
    const infoHistory = await rpc.history_get_actions(account);

    return infoHistory.actions
        .filter(({ action_trace: { act } }) => {
            return (act.data.to ? act.data.to.toString() === DEEPMINE_GAME : true) ||
                (act.account === DEEPMINE_GAME && (act.name ? act.name === 'unstake' : true))
        })
        .map(({ action_trace }) => {
            return {
                type: action_trace.act.name.replace('transfer', 'stake'),
                status: 'Successful',
                date: action_trace.block_time,
                amount: action_trace.act.data.asset_ids ? action_trace.act.data.asset_ids.length : 0,
                txId: action_trace.trx_id
            }
        });
}
