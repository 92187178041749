import React, { useContext, useEffect, useState } from 'react';

import { UALContext } from "ual-reactjs-renderer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useApp } from '../../Data/AppContext';
import { useRoutes } from '../../Hooks/Routes';
import classnames from 'classnames';

import { Header, Menu, Modal, MobileMenu, StakingModal } from '../../Components/';
import RewardsModal from '../../Components/RewardsModal/RewardsModal';

import {
    fetchDmpBalance,
    fetchNotStakedItems,
    fetchStakedItems,
    fetchWaxBalance,
    fetchTransactionHistory,
    fetchStakingConfig
} from "../../Services";

const AppContent = () => {
    const routes = useRoutes();

    const {
        isAuthenticated,
        sidebar,
        showInterface,
        userData,
        blur,
        shelter,
        scrollShelter,
        toggleRareFilter,
        toggleRareFilterHandler,
        toggleTermFilter,
        toggleTermFilterHandler,
        userLoginHandler,
        setUserDataHandler,
        setUserNotStakedItems,
        unstakedListFetched,
        setUserStakedItems,
        stakedListFetched,
        setWaxBalance,
        waxBalanceFetched,
        setDmpBalance,
        dmpBalanceFetched,
        setTransactionInfo,
        transactionInfoFetched,
        setStakingConfig,
        stakingConfig,
        stakingConfigFetched
    } = useApp();

    const { activeUser } = useContext(UALContext);

    const [notStakedItemsLoading, setNotStakedItemsLoading] = useState(false);
    const [stakedItemsLoading, setStakedItemsLoading] = useState(false);
    const [waxBalanceLoading, setWaxBalanceLoading] = useState(false);
    const [dmpBalanceLoading, setDmpBalanceLoading] = useState(false);
    const [transactionInfoLoading, setTransactionInfoLoading] = useState(false);
    const [stakingConfigLoading, setStakingConfigLoading] = useState(false);

    useEffect(() => {
        if (activeUser && activeUser.accountName && setUserDataHandler && userLoginHandler && !isAuthenticated) {
            setUserDataHandler(activeUser);
            userLoginHandler();
        }
    }, [activeUser, setUserDataHandler, userLoginHandler, isAuthenticated]);

    useEffect(() => {
        if (activeUser && activeUser.accountName
            && !unstakedListFetched && setUserNotStakedItems && !notStakedItemsLoading && stakingConfigFetched
        ) {
            setNotStakedItemsLoading(true);

            fetchNotStakedItems({ account: activeUser.accountName, stakingConfig })
                .then((items) => setUserNotStakedItems(items))
                .catch(e => {
                    toast.error(e);

                    setUserNotStakedItems([]);
                });
        }
    }, [activeUser, setUserNotStakedItems, unstakedListFetched, stakingConfig, notStakedItemsLoading, stakingConfigFetched]);

    useEffect(() => {
        if (activeUser && activeUser.accountName && !stakedListFetched && setUserStakedItems && !stakedItemsLoading) {
            setStakedItemsLoading(true);

            fetchStakedItems(({ account: activeUser.accountName }))
                .then((items) => setUserStakedItems(items))
                .catch(e => {
                    toast.error(e);

                    setUserStakedItems([]);
                });
        }
    }, [activeUser, stakedListFetched, setUserStakedItems, stakedItemsLoading]);

    useEffect(() => {
        if (activeUser && activeUser.accountName && setWaxBalance && !waxBalanceLoading && !waxBalanceFetched) {
            setWaxBalanceLoading(true);

            fetchWaxBalance({ account: activeUser.accountName })
                .then(balance => setWaxBalance(balance))
                .catch(e => toast.error(e));
        }
    }, [activeUser, waxBalanceLoading, setWaxBalance, waxBalanceFetched]);

    useEffect(() => {
        if (activeUser && activeUser.accountName && setDmpBalance && !dmpBalanceLoading && !dmpBalanceFetched) {
            setDmpBalanceLoading(true);
    
            fetchDmpBalance({ account: activeUser.accountName })
                .then(balance => setDmpBalance(balance))
                .catch(e => toast.error(e));
        }
    }, [activeUser, dmpBalanceLoading, setDmpBalance, dmpBalanceFetched]);

    useEffect(() => {
        if (activeUser && activeUser.accountName && setTransactionInfo && !transactionInfoFetched && !transactionInfoLoading) {
            setTransactionInfoLoading(true);

            fetchTransactionHistory({ account: activeUser.accountName })
                .then(info => setTransactionInfo(info))
                .catch(e => {
                    setTransactionInfo([]);

                    console.log(e);
                    toast.error(e);
                });
        }
    }, [activeUser, setTransactionInfo, transactionInfoFetched, transactionInfoLoading]);

    useEffect(() => {
        if (setStakingConfig && !stakingConfigFetched && !stakingConfigLoading) {
            setStakingConfigLoading(true);

            fetchStakingConfig()
                .then(data => setStakingConfig(data))
                .catch(e => toast.error(e));
        }
    }, [setStakingConfig, stakingConfigFetched, stakingConfigLoading]);

    return (
        <div 
            className={
                classnames('app_wrapper', {
                    auth: isAuthenticated, 
                    success: (userData && !userData.butNFT && !isAuthenticated), 
                    'shelter': shelter, 
                    'shelter__scroll': scrollShelter,
                    'error': userData?.butNFT,
                })}
                onClick = {() => {
                    if (!toggleRareFilter && !toggleTermFilter)
                        return;

                    toggleRareFilterHandler(false);
                    toggleTermFilterHandler(false);
                }}    
            >
            <div className={classnames('app_wrapper__container', {'app_wrapper__container_interface': !showInterface, blur: blur })}>
                <Header/>

                <main className={classnames('main_wrapper', {'main_wrapper__sidebar': sidebar, menu_hide: !showInterface})}>
                    <Menu />
                    { routes }
                </main>

                <MobileMenu />
            </div>
            <Modal />
            <StakingModal />
            <RewardsModal />

            <ToastContainer
                position="bottom-left"
                autoClose={4000}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </div>
    )
}

export default AppContent;