import React from "react";
import classnames from 'classnames';

import SidebarHide from '../../assets/img/adaptive_background/SidebarHide.png';

import WaxLogo from "../Header/WaxLogo";

const LogoutBlock = ({ showInterface }) => {
    return (
        <div className={classnames('sidebar_block', 'logout_block', { 'hide': showInterface })}>
            <img src={ SidebarHide } alt="" />

            <WaxLogo />
        </div>
    )
}

export default LogoutBlock;