import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../Hooks/WindowDimensions";

import contentBg4096 from '../../assets/img/adaptive_background/content4096.png';
import contentBg3840 from '../../assets/img/adaptive_background/content3840.png';
import contentBg2560 from '../../assets/img/adaptive_background/content2560.png';
import contentBg1920 from '../../assets/img/adaptive_background/content1920.png';
import contentBg2048 from '../../assets/img/adaptive_background/content2048.png';
import contentBg1440 from '../../assets/img/adaptive_background/content1440.png';
import contentBg1366 from '../../assets/img/adaptive_background/content1366.png';
import contentBg1280 from '../../assets/img/adaptive_background/content1280.png';
import contentBg1024 from '../../assets/img/adaptive_background/content1024.png';
import contentBg768 from '../../assets/img/adaptive_background/content768.png';

import contentBgH4096 from '../../assets/img/adaptive_background/ContentB4096.png';
import contentBgH3840 from '../../assets/img/adaptive_background/ContentB3840.png';
import contentBgH2560 from '../../assets/img/adaptive_background/ContentB2560.png';
import contentBgH1920 from '../../assets/img/adaptive_background/ContentB1920.png';
import contentBgH2048 from '../../assets/img/adaptive_background/ContentB2048.png';
import contentBgH1440 from '../../assets/img/adaptive_background/ContentB1440.png';
import contentBgH1366 from '../../assets/img/adaptive_background/ContentB1366.png';
import contentBgH1280 from '../../assets/img/adaptive_background/ContentB1280.png';
import contentBgH1024 from '../../assets/img/adaptive_background/ContentB1024.png';
import contentBgH768 from '../../assets/img/adaptive_background/ContentB768.png';

import contentHide4096 from '../../assets/img/adaptive_background/ContentHide4096.png';
import contentHide3840 from '../../assets/img/adaptive_background/ContentHide3840.png';
import contentHide2560 from '../../assets/img/adaptive_background/ContentHide2560.png';
import contentHide2048 from '../../assets/img/adaptive_background/ContentHide2048.png';
import contentHide1920 from '../../assets/img/adaptive_background/ContentHide1920.png';
import contentHide1440 from '../../assets/img/adaptive_background/ContentHide1440.png';
import contentHide1366 from '../../assets/img/adaptive_background/ContentHide1366.png';
import contentHide1280 from '../../assets/img/adaptive_background/ContentHide1280.png';
import contentHide1024 from '../../assets/img/adaptive_background/ContentHide1024.png';
import contentHide768 from '../../assets/img/adaptive_background/ContentHide768.png';

//Todo: Компонент вообще переделать, подумать как сделать кастомный хук для определения соотношения и перерендера этих блоков без двойного рендера как сейчас!
const ContentBg = ({ isAuthenticated, toggleSidebarHadler, showInterface, toggleBlurHandler, toggleShelterHandler }) => {
    const [imageShow, setImageShow] = useState(false);
    const [lastSetupAuth, setLastSetupAuth] = useState(false);


    const { width } = useWindowDimensions();
    let contentImg_low = null;
    let contentImg_high = null;
    let contentImg_hide = null;

    if (width <= 4096 && width > 3840 ) {
        contentImg_low = contentBg4096;
        contentImg_high = contentBgH4096;
        contentImg_hide = contentHide4096;
    } else if (width <= 3840 && width > 2580) {
        contentImg_low = contentBg3840;
        contentImg_high = contentBgH3840;
        contentImg_hide = contentHide3840;
    } else if (width <= 2580 && width > 2048) {
        contentImg_low = contentBg2560;
        contentImg_high = contentBgH2560;
        contentImg_hide = contentHide2560;
    } else if (width <= 2048 && width > 1920) {
        contentImg_low = contentBg2048;
        contentImg_high = contentBgH2048;
        contentImg_hide = contentHide2048;
    } else if (width <= 1920 && width > 1440) {
        contentImg_low = contentBg1920;
        contentImg_high = contentBgH1920;
        contentImg_hide = contentHide1920;
    } else if (width <= 1440 && width > 1366) {
        contentImg_low = contentBg1440;
        contentImg_high = contentBgH1440;
        contentImg_hide = contentHide1440;
    } else if (width <= 1366 && width > 1280) {
        contentImg_low = contentBg1366;
        contentImg_high = contentBgH1366;
        contentImg_hide = contentHide1366;
    } else if (width <= 1280 && width > 1024) {
        contentImg_low = contentBg1280;
        contentImg_high = contentBgH1280;
        contentImg_hide = contentHide1280;
    } else if (width <= 1024 && width > 768) {
        contentImg_low = contentBg1024;
        contentImg_high = contentBgH1024;
        contentImg_hide = contentHide1024;
    } else if (width <= 768 ) {
        contentImg_low = contentBg768;
        contentImg_high = contentBgH768;
        contentImg_hide = contentHide768;
    }

    useEffect(() => {
        if (lastSetupAuth !== isAuthenticated) {
            const url = window.location.pathname;

            if (url === '/' ) {
                toggleBlurHandler(false);
                toggleSidebarHadler(false);

                if (isAuthenticated)
                    toggleShelterHandler(true);
            } else {
                toggleShelterHandler(false);
                setImageShow(true);
                toggleBlurHandler(true);

                if (isAuthenticated && url !== '/Information')
                    toggleSidebarHadler(true);
            }

            setLastSetupAuth(isAuthenticated);
        }
    }, [lastSetupAuth, isAuthenticated, toggleBlurHandler, toggleShelterHandler, toggleSidebarHadler]);


    if (imageShow && !isAuthenticated && showInterface) {
        return (
            <img className="content__bg" src={ contentImg_high } alt="" />
        )
    }

    if (imageShow && isAuthenticated && showInterface) {
        return (
            <img className="content__bg" src={ contentImg_low } alt="" />
        )
    }

    if (imageShow && !showInterface) {
        return (
            <img className="content__bg" src={contentImg_hide} alt=""/>
        )
    }

    if (!imageShow) {
        return '';
    }
}

export default ContentBg;