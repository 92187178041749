import React from "react";

import { useApp } from "../../Data/AppContext";

import TransactionItem from "./TransactionItem";

const TransactionTable = () => {
    const { transactionsInfo } = useApp();

    return (
        <div className="transaction_block">
            <div className="transaction_block__wrapper">
                <table className='transaction_table'>
                    <thead>
                        <tr className='transaction_table__header'>
                            <th className='table_id'>

                            </th>
                            <th>
                                Type
                            </th>
                            <th>
                                Date
                            </th>
                            <th>
                                Amount
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Tx ID
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { transactionsInfo
                            .map((item) =>
                                <TransactionItem
                                    key={item.id}
                                    id={item.id}
                                    type={item.type}
                                    status={item.status}
                                    date={item.date.slice(0, 10)}
                                    amount={item.amount}
                                    txId={item.txId}
                                    minTxId={item.txId.slice(0, 8).toUpperCase()}
                                />
                            )
                                .reverse()
                        }
                    </tbody>
                </table>         
            </div>
        </div>
    )
}

export default TransactionTable;