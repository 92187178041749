import React from 'react';

import SimpleButton from '../Ui/SimpleButton/SimpleButton';

import SidImg2_4096 from '../../assets/img/adaptive_background/sidebar4096-2.png';
import SidImg2_3840 from '../../assets/img/adaptive_background/sidebar3840-2.png';
import SidImg2_2560 from '../../assets/img/adaptive_background/sidebar2560-2.png';
import SidImg2_2048 from '../../assets/img/adaptive_background/sidebar2048-2.png';
import SidImg2_1920 from '../../assets/img/adaptive_background/sidebar1920-2.png';
import SidImg2_1440 from '../../assets/img/adaptive_background/sidebar1440-2.png';
import SidImg2_1366 from '../../assets/img/adaptive_background/sidebar1366-2.png';
import SidImg2_1280 from '../../assets/img/adaptive_background/sidebar1280-2.png';
import SidImg2_1024 from '../../assets/img/adaptive_background/sidebar1024-2.png';
import SidImg2_768 from '../../assets/img/adaptive_background/sidebar768-2.png';

import { countDmpToClaim } from "../../Helpers";
import { useApp } from "../../Data/AppContext";

const EarnedBlock = ({ width }) => {
    const { stakedList, showPopupHandler } = useApp();

    let sidImg = null;

    if (width <= 4096 && width > 3840 ) {
        sidImg = SidImg2_4096;
    } else if (width <= 3840 && width > 2580) {
        sidImg = SidImg2_3840;
    } else if (width <= 2580 && width > 2048) {
        sidImg = SidImg2_2560;
    } else if (width <= 2048 && width > 1920) {
        sidImg = SidImg2_2048;
    } else if (width <= 1920 && width > 1440) {
        sidImg = SidImg2_1920;
    } else if (width <= 1440 && width > 1366) {
        sidImg = SidImg2_1440;
    } else if (width <= 1366 && width > 1280) {
        sidImg = SidImg2_1366;
    } else if (width <= 1280 && width > 1024) {
        sidImg = SidImg2_1280;
    } else if (width <= 1024 && width > 768) {
        sidImg = SidImg2_1024;
    } else if (width <= 768 ) {
        sidImg = SidImg2_768;
    }

    const dmpToStake = countDmpToClaim({ stakedItems: stakedList });

    return (
        <div className="sidebar_block earned_block">
            <img src={sidImg} alt="" />
            <h3 className='block_title'>
                DMP to claim
            </h3>

            <div className="block_information">
                <div className="information_item">
                    <div className="information_value">
                        <div className="value">
                            { dmpToStake }
                        </div>
                        <span className="postscript">
                        Equivalent in DM
                        </span>
                    </div>
                </div>
            </div>

            <div className="button_block">
                <SimpleButton handler = {() => showPopupHandler('claim')}>
                    Claim
                </SimpleButton>
            </div>
        </div>
    )
}

export default EarnedBlock;