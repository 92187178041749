import React, { useState } from 'react';
import FaqItem from './FaqItem';

const FaqList = () => {
    const [active, setActive] = useState(false);

    const questions = [
        { id: 0, question: 'Can I stake my Deep Mine NFTs?', answer: '“Common” NFTs can be purchased. However, “Rare” and “Epic” NFTs can only be won on Deep Mine. The fundamental difference between “Common” and “Rare”/”Epic” NFTs is that “Rare”/”Epic” NFTs offer higher rewards via NFT staking.' },
        { id: 1, question: 'Can I stake DM tokens?', answer: '“Common” NFTs can be purchased. However, “Rare” and “Epic” NFTs can only be won on Deep Mine. The fundamental difference between “Common” and “Rare”/”Epic” NFTs is that “Rare”/”Epic” NFTs offer higher rewards via NFT staking.' },
        { id: 2, question: 'What does “up to 300% APY” mean?', answer: '“Common” NFTs can be purchased. However, “Rare” and “Epic” NFTs can only be won on Deep Mine. The fundamental difference between “Common” and “Rare”/”Epic” NFTs is that “Rare”/”Epic” NFTs offer higher rewards via NFT staking.' },
        { id: 3, question: 'Will I be able to stake Deep Mine tokens (DM) without buying NFTs?', answer: '“Common” NFTs can be purchased. However, “Rare” and “Epic” NFTs can only be won on Deep Mine. The fundamental difference between “Common” and “Rare”/”Epic” NFTs is that “Rare”/”Epic” NFTs offer higher rewards via NFT staking.' },
        { id: 4, question: 'Where can I obtain Deep Mine tokens (DM)?', answer: '“Common” NFTs can be purchased. However, “Rare” and “Epic” NFTs can only be won on Deep Mine. The fundamental difference between “Common” and “Rare”/”Epic” NFTs is that “Rare”/”Epic” NFTs offer higher rewards via NFT staking.' },
        { id: 5, question: 'Why are some NFTs listed as “Common” while other NFTs are listed as “Rare” or “Epic”?', answer: '“Common” NFTs can be purchased. However, “Rare” and “Epic” NFTs can only be won on Deep Mine. The fundamental difference between “Common” and “Rare”/”Epic” NFTs is that “Rare”/”Epic” NFTs offer higher rewards via NFT staking.' },
        { id: 6, question: 'What is the difference between the types of Deep MineNFTs (e.g., “Black,” “Aqua,” etc.)?', answer: '“Common” NFTs can be purchased. However, “Rare” and “Epic” NFTs can only be won on Deep Mine. The fundamental difference between “Common” and “Rare”/”Epic” NFTs is that “Rare”/”Epic” NFTs offer higher rewards via NFT staking.' },
        { id: 7, question: 'What is the difference between Deep Mine NFT staking and 4Bulls token (4B) staking?', answer: '“Common” NFTs can be purchased. However, “Rare” and “Epic” NFTs can only be won on Deep Mine. The fundamental difference between “Common” and “Rare”/”Epic” NFTs is that “Rare”/”Epic” NFTs offer higher rewards via NFT staking.' },
    ];

    return (
        <div className="faq_list">
            <div className="faq_list_wrap">
                { questions.map(question =>
                    <FaqItem
                        key={question.id}
                        id={question.id}
                        question={question.question}
                        answer={question.answer}
                        active={active}
                        setActive={setActive}
                    />
                ) }
            </div>
        </div>

    )
}

export default FaqList;