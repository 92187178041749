import React from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as BackImg } from '../../../assets/img/icons/back.svg';

const BackButton = ({ children }) => {
    return (
        <NavLink to='/' className='back_btn'>
            <BackImg className='back_btn__img'/>
            { children }
        </NavLink>
    )
}

export default BackButton;