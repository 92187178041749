import React, { useContext } from "react";

import { UALContext } from "ual-reactjs-renderer";
import { useApp } from "../../Data/AppContext";

import SimpleButton from "../Ui/SimpleButton/SimpleButton";

import { ReactComponent as LogoutImg } from '../../assets/img/icons/logout.svg';

const StatsInformation = () => {
    const { logout } = useContext(UALContext);
    const { userData, waxBalance, dmpBalance, userLogoutHandler } = useApp();

    const handleLogout = () => {
        logout();
        userLogoutHandler();
    };

    return (
        <div className='stats_bar'>
            <div className="user_info">
                <div className="user_info__name">
                    Citizen: { userData?.accountName }
                </div>
                <div className="user_info__stats">
                    <span className="dmp_price">
                        { dmpBalance }
                    </span>
                    <span className=" ">
                        { waxBalance }
                    </span>  
                </div>
            </div>

            <SimpleButton handler={handleLogout}>
                <LogoutImg className='logout_img' />
                Leave Shelter
            </SimpleButton>            
        </div>
    )
}

export default StatsInformation;