import React from "react";

import RewardsImg from '../../assets/img/adaptive_background/RewardsSidebar.png';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/icon.svg';

import SimpleButton from '../Ui/SimpleButton/SimpleButton';

const RewardsInfo = () => {
    return (
        <div className="sidebar_block rewards-info_block">
            <img src={RewardsImg} alt="" />
            <h3 className='block_title'>
                Rewards Info
            </h3>
            <div className="block_information">
                <div className="information_item">
                    <div className="information_title">
                        DMP Available
                        <span className='info_block'>
                            <InfoIcon />
                        </span>
                    </div>
                    <div className="information_value">
                        <div className="value">
                            {/*TODO: remove hardcode DMP Available*/}
                            = $2323
                        </div>
                        <span className="postscript">
                        Equivalent in DM
                        </span>
                    </div>
                </div>
                <div className="information_item">
                    <div className="information_title">
                        DMP Cost
                        <span className='info_block'>
                            <InfoIcon />
                        </span>
                    </div>
                    <div className="information_value">
                        <div className="value">
                            {/*TODO: remove hardcode DMP Cost*/}
                            = $332
                        </div>
                        <span className="postscript">
                        Equivalent in DM
                        </span>
                    </div>
                </div>
                <div className="information_item">
                    <div className="information_title">
                        DMP Result
                        <span className='info_block'>
                            <InfoIcon />
                        </span>
                    </div>
                    <div className="information_value">
                        <div className="value">
                            {/*TODO: remove hardcode DMP Result*/}
                            = $2323
                        </div>
                        <span className="postscript">
                        Equivalent in DM
                        </span>
                    </div>
                </div>
                <div className="information_item indent">
                    <div className="information_title">
                        NFTs Selected
                        <span className='info_block'>
                            <InfoIcon />
                        </span>
                    </div>
                    <div className="information_value">
                        <div className="value">
                            {/*TODO: remove hardcode NFTs Selected*/}
                            = $332
                        </div>
                        <span className="postscript">
                        Equivalent in DM
                        </span>
                    </div>
                </div>
                <div className="information_item">
                    <div className="information_title">
                        DMP Result
                        <span className='info_block'>
                            <InfoIcon />
                        </span>
                    </div>
                    <div className="information_value">
                        <div className="value">
                            {/*TODO: remove hardcode DMP Result*/}
                            = $100
                        </div>
                        <span className="postscript">
                        Equivalent in DM
                        </span>
                    </div>
                </div>
            </div>
            <div className="button_block">
                <SimpleButton active={true}>
                    Claim Reward
                </SimpleButton>
            </div>
        </div>
    )
}

export default RewardsInfo;