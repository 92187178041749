import React from "react";

import { useApp } from '../../Data/AppContext';
import classnames from 'classnames';

import ContentBg from "../../Containers/App/ContentBg";
import { HideInterfaceHeader } from "..";

const ContentContainer = ({ children }) => {
    const { isAuthenticated, toggleSidebarHadler, showInterface, toggleBlurHandler, toggleShelterHandler} = useApp();

    return (
        <div className={classnames('content', { hide_interface: !showInterface})}>
            <HideInterfaceHeader />
            <ContentBg 
                isAuthenticated={ isAuthenticated } 
                toggleSidebarHadler={ toggleSidebarHadler } 
                showInterface={ showInterface }
                toggleBlurHandler={ toggleBlurHandler }
                toggleShelterHandler={ toggleShelterHandler }
            />

            <div className={classnames('content__container', { 'not_auth': !isAuthenticated})}>
                { children }
            </div>
        </div>
    )
}

export default ContentContainer;