import React, { useContext, useEffect, useState } from 'react'

import { UALContext } from "ual-reactjs-renderer";
import { useApp } from "../../Data/AppContext";

import classnames from 'classnames';
import BackButton from '../Ui/BackButton/BackButton';
import ButtonLink from '../Ui/ButtonLink/ButtonLink';
import Button from '../Ui/Button/Button';
import InformationBtn from './InformationBtn';
import SimpleButton from '../Ui/SimpleButton/SimpleButton';
import FilterSelector from '../Ui/FilterSelector/FilterSelector';

import {
    ALL,
    COMMON,
    UNCOMMON,
    RARE,
    EPIC,
    LEGENDARY,
    UNSTAKE_PAGE,
    STAKE_PAGE,
    PRE_LAUNCH_POOL,
    DAILY_POOL,
    ALL_TERM
} from "../../Constants";

const BtnBlock = ({
    faq = false,
    pageType,
    pageTypeSecond,
    scroll,
    stakeOrUnstakeBtnText,
    stakeOrUnstakeBtnHandler,
    page,
    selectedCards,
    type,
    setPage,
    setSelectedRarity,
    setSelectedTerm
}) => {
    const {
        isAuthenticated,
        toggleRareFilter,
        toggleRareFilterHandler,
        toggleTermFilter,
        toggleTermFilterHandler,
    } = useApp();

    const { showModal } = useContext(UALContext);
    
    const [rarityFilter, setRarityFilter] = useState({
        label: 'Rarity',
        value: '',
        items: [ALL, COMMON, UNCOMMON, RARE, EPIC, LEGENDARY],
    });

    const [termFilter, setTermFilter] = useState({
        label: 'Term',
        value: '',
        items: [ALL_TERM, DAILY_POOL, PRE_LAUNCH_POOL],
    });

    const raritySelectFilterHandler = (value) => {
        setRarityFilter({...rarityFilter, value});
        toggleRareFilterHandler(false);
    }

    const termSelectFilterHandler = (value) => {
        setTermFilter({...termFilter, value});
        toggleTermFilterHandler(false);
    }

    useEffect(() => {
        if (rarityFilter && setSelectedRarity)
            setSelectedRarity(rarityFilter.value);
    }, [rarityFilter, setSelectedRarity]);

    useEffect(() => {
        if (termFilter && setSelectedTerm)
            setSelectedTerm(termFilter.value);
    }, [termFilter, setSelectedTerm]);

    if ( isAuthenticated && !faq ) {
        return (
            <div className={
                classnames('btn_block btn_block__is_auth', {'btn_block__scroll': scroll, 'rewards': type === 'rewards'})
            }>
                <div className="btn_container">
                    <div className="page_type">
                        <div className="mobile_page_btn">
                            { page === UNSTAKE_PAGE
                                ? <SimpleButton
                                    paint={true}
                                    handler={() => setPage(UNSTAKE_PAGE)}
                                >
                                    { pageType }
                                </SimpleButton>
                                : <SimpleButton
                                    hideStroke={ true }
                                    handler={() => setPage(UNSTAKE_PAGE)}
                                >
                                    { pageType }
                                </SimpleButton>
                            }

                            { page === STAKE_PAGE
                                ? <SimpleButton
                                    paint={ true }
                                    handler={() => setPage(STAKE_PAGE)}
                                >
                                    { pageTypeSecond }
                                </SimpleButton>
                                : <SimpleButton
                                    hideStroke={ true }
                                    handler={() => setPage(STAKE_PAGE)}
                                >
                                    { pageTypeSecond }
                                </SimpleButton>
                            }
                        </div>

                        <div className="filters__container">
                            <div className="filter_block">
                                <FilterSelector
                                    active={toggleRareFilter}
                                    selectedValue={rarityFilter.value}
                                    items={rarityFilter.items}
                                    selectorToggle={()=>toggleRareFilterHandler(!toggleRareFilter)}
                                    selectValue={raritySelectFilterHandler}
                                    label = {rarityFilter.label}
                                />

                                { page === UNSTAKE_PAGE &&
                                    <FilterSelector
                                        active={toggleTermFilter}
                                        selectedValue={termFilter.value}
                                        items={termFilter.items}
                                        selectorToggle={() => toggleTermFilterHandler(!toggleTermFilter)}
                                        selectValue={termSelectFilterHandler}
                                        label={termFilter.label}
                                    />
                                }
                            </div>
                        </div>

                    </div>

                    { stakeOrUnstakeBtnText &&
                        <InformationBtn
                            buttonText={stakeOrUnstakeBtnText}
                            buttonHandler={stakeOrUnstakeBtnHandler}
                            selectedCards={selectedCards}
                        />
                    }

                </div>
            </div>
        );
    }
    
    return (
        <div className="btn_block">
            <BackButton>Go back</BackButton>

            { !isAuthenticated && faq
                ? <div className="information_btn">
                    <ButtonLink
                        onClick={() => showModal()}
                        active='true'
                    >
                        Connect Wallet
                    </ButtonLink>

                    <Button to={'/Information'}>How it works</Button>
                </div>
                : ""
            }
            
        </div>
    )
}

export default BtnBlock;