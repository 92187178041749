import React, { useContext } from "react";
import { UALContext } from "ual-reactjs-renderer";
import { useApp } from "../../Data/AppContext";

import { toast } from "react-toastify";

import SimpleButton from "../Ui/SimpleButton/SimpleButton";

import { fetchNotStakedItems, unstakeItems } from "../../Services";
import { STAKING_DURATION_FIXED } from "../../Constants";

const UnstakeModal = ({ hidePopupHandler }) => {
    const { activeUser } = useContext(UALContext);

    const {
        stakingConfig,
        setUserNotStakedItems,
        selectedItemsForUnstake,
        setSelectedItemsForUnstake,
        removeItemsFromUserStakedItems
    } = useApp();

    const handleUnstake = () => {
        if (!selectedItemsForUnstake.length) {
            toast.warning('Select items for unstake');

            return hidePopupHandler();
        }

        if (selectedItemsForUnstake.find(({ stakingInfo: { stakingDuration } }) => stakingDuration === STAKING_DURATION_FIXED)) {
            toast.warning('Can not unstake locked item until 15 May');

            return hidePopupHandler();
        }

        unstakeItems({
            activeUser,
            items: selectedItemsForUnstake.map(({ asset_id }) => asset_id),
            //TODO: get fixed / days param
            fixed: false,
            days: true
        })
            .then(() => {
                fetchNotStakedItems({ account: activeUser.accountName, stakingConfig })
                    .then((items) => setUserNotStakedItems(items))
                    .catch(e => console.log(e));

                removeItemsFromUserStakedItems(selectedItemsForUnstake);
                setSelectedItemsForUnstake([]);

                toast.success('Unstaked');
            })
            .catch((e) => toast.error(e))
            .finally(() => hidePopupHandler());
    }

    return (
        <div className='content_block unstake_modal'>
            <h2>
                Are sure you want to unstake?
            </h2>

            <div className="button_blocks">
                <SimpleButton handler={hidePopupHandler}>
                    No
                </SimpleButton>

                <SimpleButton handler={handleUnstake}>
                    Yes
                </SimpleButton>
            </div>
        </div>
    )
}

export default UnstakeModal;