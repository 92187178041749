import React from "react";

import { useApp } from "../../Data/AppContext";
import useWindowDimensions from "../../Hooks/WindowDimensions";

import classnames from 'classnames';
import NavList from "./NavList";

import menuBg4096 from '../../assets/img/adaptive_background/menu4096.png';
import menuBg3840 from '../../assets/img/adaptive_background/menu3840.png';
import menuBg2560 from '../../assets/img/adaptive_background/menu2560.png';
import menuBg2048 from '../../assets/img/adaptive_background/menu2048.png';
import menuBg1920 from '../../assets/img/adaptive_background/menu1920.png';
import menuBg1440 from '../../assets/img/adaptive_background/menu1440.png';
import menuBg1366 from '../../assets/img/adaptive_background/menu1366.png';
import menuBg1280 from '../../assets/img/adaptive_background/menu1280.png';
import menuBg1024 from '../../assets/img/adaptive_background/menu1024.png';
import menuBg768 from '../../assets/img/adaptive_background/menu768.png';

const Menu = () => {
    const { showInterface, isAuthenticated } = useApp();

    const { width } = useWindowDimensions();
    let menuImg = null;

    if (width <= 4096 && width > 3840 ) {
        menuImg = menuBg4096;
    } else if (width <= 3840 && width > 2560) {
        menuImg = menuBg3840;
    } else if (width <= 2560 && width > 2048) {
        menuImg = menuBg2560;
    } else if (width <= 2048 && width > 1920) {
        menuImg = menuBg2048;
    }  else if (width <= 1920 && width > 1440) {
        menuImg = menuBg1920;
    } else if (width <= 1440 && width > 1366) {
        menuImg = menuBg1440;
    } else if (width <= 1366 && width > 1280) {
        menuImg = menuBg1366;
    } else if (width <= 1280 && width > 1024) {
        menuImg = menuBg1280;
    } else if (width <= 1024 && width > 768) {
        menuImg = menuBg1024;
    } else if (width <= 768 ) {
        menuImg = menuBg768;
    }

    return (
        <aside className={classnames('menu_block', { 'show': showInterface })}>
            <img src={ menuImg } alt="" className='menu_block__bg'/>

            <div className="menu_container">
                <nav className='nav_container'>
                    <NavList isAuthenticated = {isAuthenticated} />
                </nav>
            </div>
        </aside>
    )
}

export default Menu;