import React from "react";

import { ReactComponent as LogoImg } from '../../assets/img/header/header_logo.svg';

const Logo = ({ toggleInterface, showInterface, width }) => {
    const toggleInterfaseHandler = () => {
        if (width <= 768) return;
        toggleInterface(!showInterface);
    }

    return (
        <div className='header_logo'>
            <button className='header_logo__btn' onClick={ toggleInterfaseHandler }>
                <LogoImg className='header_logo__img'/>
            </button>
        </div>
    )
}

export default Logo;