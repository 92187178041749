import React from "react";
import { NavLink } from "react-router-dom";

const Button = ({ children, to }) => {
    return (
        <NavLink to={to} className='btn'>
            <span className='btn__wrap'>
                { children }
            </span>
        </NavLink>
    )
}

export default Button;