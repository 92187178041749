import React from "react";

import SimpleButton from "../Ui/SimpleButton/SimpleButton";

const InformationBtn = ({ selectedCards, buttonText, buttonHandler }) => {
    return (
        <div className="information_btn">
            { buttonText &&
                <SimpleButton active={selectedCards.length > 0} handler={buttonHandler}>
                    { buttonText }
                </SimpleButton>
            }
        </div>
    );
}

export default InformationBtn;