import React from "react";

import classnames from 'classnames';
import { useApp } from "../../Data/AppContext";

import Logo from "./Logo";

const HideInterfaceHeader = () => {
    const { showInterface, toggleInterfaceHandler } = useApp();

    return (
        <header className={classnames('second_header', { 'show': !showInterface })}>
            <Logo toggleInterface={ toggleInterfaceHandler } showInterface = { showInterface } />
        </header>
    );
}

export default HideInterfaceHeader;