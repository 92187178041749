import React from "react";

import { useApp } from "../../Data/AppContext";
import useWindowDimensions from '../../Hooks/WindowDimensions';

import SimpleButton from "../Ui/SimpleButton/SimpleButton";

import Bar3840 from '../../assets/img/adaptive_background/bar3840.png';
import Bar2560 from '../../assets/img/adaptive_background/bar2560.png';
import Bar2048 from '../../assets/img/adaptive_background/bar2048.png';
import Bar1920 from '../../assets/img/adaptive_background/bar1920.png';
import Bar1440 from '../../assets/img/adaptive_background/bar1440.png';
import Bar1366 from '../../assets/img/adaptive_background/bar1366.png';
import Bar1280 from '../../assets/img/adaptive_background/bar1280.png';
import Bar1024 from '../../assets/img/adaptive_background/bar1024.png';
import Bar768 from '../../assets/img/adaptive_background/bar768.png';

import { UNSTAKE_PAGE, STAKE_PAGE } from '../../Constants';

const StatusBarStaking = ({ type, pageType, pageTypeSecond, setPage, page  }) => {
    const { dmpBalance } = useApp();
    const { width } = useWindowDimensions();

    let barImg = null;
    
    if (width <= 4096 && width > 3840 ) {
        barImg = Bar3840;
    } else if (width <= 3840 && width > 2580) {
        barImg = Bar3840;
    } else if (width <= 2580 && width > 2048) {
        barImg = Bar2560;
    } else if (width <= 2048 && width > 1920) {
        barImg = Bar2048;
    } else if (width <= 1920 && width > 1440) {
        barImg = Bar1920;
    } else if (width <= 1440 && width > 1366) {
        barImg = Bar1440;
    } else if (width <= 1366 && width > 1280) {
        barImg = Bar1366;
    } else if (width <= 1280 && width > 1024) {
        barImg = Bar1280;
    } else if (width <= 1024 && width > 768) {
        barImg = Bar1024;
    } else if (width <= 768 ) {
        barImg = Bar768;
    }

    if (type === 'rewards') {
        return (
            <div className='status-bar rewards'>
                <div className="img__block">
                    <img src={ barImg } alt="" />
                </div>
                <div className="status-bar__container">
                    <div className="status_item">
                        <div className="title">DMP Total</div>
                        { dmpBalance.replace(' DMP', '') }
                    </div>
                    <div className="status_item">
                        <div className="title">Claimed NFT Reward</div>
                        {/*TODO: remove hardcode*/}
                        23423423
                    </div>
                    <div className="status_item">
                        <div className="title">Reward NFT Collection</div>
                        {/*TODO: remove hardcode*/}
                        2342
                    </div>
                </div>
            </div>
        ) 
    }
    
    return (
        <div className='status-bar'>
            <div className="img__block">
                <img src={ barImg } alt="" />
            </div>
            <div className="status-bar__container">
                {
                    page === UNSTAKE_PAGE
                        ? <SimpleButton
                            paint={true}
                            handler={() => setPage(UNSTAKE_PAGE)}
                        >
                            { pageType }
                        </SimpleButton>
                        : <SimpleButton
                            hideStroke={ true }
                            handler={() => setPage(UNSTAKE_PAGE)}
                        >
                            { pageType }
                        </SimpleButton>
                }

                {
                    page === STAKE_PAGE
                        ? <SimpleButton
                            paint={ true }
                            handler={() => setPage(STAKE_PAGE)}
                        >
                            { pageTypeSecond }
                        </SimpleButton>
                        : <SimpleButton
                            hideStroke={ true }
                            handler={() => setPage(STAKE_PAGE)}
                        >
                            { pageTypeSecond }
                        </SimpleButton>
                }

                {/* <div className="status_item">
                    <div className="title">Liquid DMP</div>
                    2444
                </div>
                <div className="status_item">
                    <div className="title">DMP Earned</div>
                    2444
                </div>
                <div className="status_item">
                    <div className="title">Staked NFTs</div>
                    12
                </div>
                <div className="status_item">
                    <div className="title">Not Staked NFTs</div>
                    09
                </div> */}
            </div>
        </div>
    )
}

export default StatusBarStaking;