import React, { useContext } from "react";

import { UALContext } from "ual-reactjs-renderer";
import classnames from 'classnames';

import HandImgPng from '../../assets/img/hand.png';
import BuyImgPng from '../../assets/img/buyNft.png';
import SuccessImgPng from '../../assets/img/success.png';

const LoginButton = ({ type }) => {
    const { showModal} = useContext(UALContext);

    return (
        <button
            className={classnames('login-btn', type)}
            onClick={() => { showModal() }}
        >
            { type === 'auth' 
                ? <img src={HandImgPng} alt="" />
                : <>
                    { type === 'warning'
                        ? <img src={BuyImgPng} alt="" />
                        : <img src={SuccessImgPng} alt="" />
                    }
                </>
            }
        </button>
    );
}

export default LoginButton;