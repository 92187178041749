import React from "react";

import { useApp } from "../../Data/AppContext";
import classnames from "classnames";

import popupBg from '../../assets/img/popupBg.png';
import popupBg2 from '../../assets/img/popupBg2.png';

import { LoginModal, UnstakeModal } from "..";
import ClaimModal from "./ClaimModal";
import StakingModal from "./StakingModal";
import Congralutations from "./Congralitations";
import DMPModal from "./DMPModal";

const Modal = () => {
    const { showPopup, popupType, hidePopupHandler } = useApp();

    return (
        <div
            className={classnames(popupType, "modal", { active: showPopup })}
             onClick={() => hidePopupHandler() }
        >
            <div
                className={classnames(popupType, 'modal__content', { active: showPopup })}
                onClick = {event => event.stopPropagation() }
            >
                {
                    (popupType === 'congratulations' || popupType === 'DMP')
                        ? <img src={popupBg2} alt="img" className='modal__img'/>
                        : <img src={popupBg} alt="img" className='modal__img' />
                }

                {
                    {
                        'login'     : <LoginModal />,
                        'unstake'   : <UnstakeModal hidePopupHandler={hidePopupHandler} />,
                        'claim'     : <ClaimModal hidePopupHandler={hidePopupHandler} />,
                        'staking'   : <StakingModal hidePopupHandler={hidePopupHandler} />,
                        'congratulations' : <Congralutations />,
                        'DMP'       : <DMPModal />
                    }[popupType]
                }
            </div>
        </div>
    );
};

export default Modal;
