import React from "react";

import classnames from 'classnames';
import { useApp } from "../../Data/AppContext";

import { ReactComponent as InfoIcon } from '../../assets/img/icons/icon.svg';
// import { ReactComponent as CopyIcon } from '../../assets/img/icons/icon-copy.svg';

import SimpleButton from "../Ui/SimpleButton/SimpleButton";

const RewardsModal = () => {
    const { rewardsPopup, toggleRewardsPopupHandler } = useApp();

    // const copyLink = (link) => {
    //     navigator.clipboard.writeText(link);
    // }

    return (
        <div className={ classnames('rewards_popup', {active: rewardsPopup}) }>
            <div className="close_modal">
                <button onClick={() => toggleRewardsPopupHandler(false)}>
                    Close <span>x</span>
                </button>
            </div>

            <div className="rewards_container">
                    <div className="staking_info">
                        <h3 className='block_title'>
                            Rewards Info
                        </h3>
                        <div className="block_information">
                            <div className="information_item">
                                <div className="information_title">
                                    DMP Liquid
                                    <span className='info_block'>
                                        <InfoIcon />
                                    </span>
                                </div>
                                <div className="information_value">
                                    <div className="value">
                                        {/*TODO: remove hardcode DMP Liquid*/}
                                        = $2323
                                    </div>
                                    <span className="postscript">
                                    Equivalent in DM
                                    </span>
                                </div>
                            </div>
                            <div className="information_item">
                                <div className="information_title">
                                    DMP Cost
                                    <span className='info_block'>
                                        <InfoIcon />
                                    </span>
                                </div>
                                <div className="information_value">
                                    <div className="value">
                                        {/*TODO: remove hardcode DMP Cost*/}
                                        = $2323
                                    </div>
                                    <span className="postscript">
                                    Equivalent in DM
                                    </span>
                                </div>
                            </div>
                            <div className="information_item">
                                <div className="information_title">
                                    DMP Result
                                    <span className='info_block'>
                                        <InfoIcon />
                                    </span>
                                </div>
                                <div className="information_value">
                                    <div className="value">
                                        {/*TODO: remove hardcode DMP Result*/}
                                        = $2323
                                    </div>
                                    <span className="postscript">
                                    Equivalent in DM
                                    </span>
                                </div>
                            </div>
                            <div className="information_item indent">
                                <div className="information_title">
                                    NFTs Selected
                                    <span className='info_block'>
                                        <InfoIcon />
                                    </span>
                                </div>
                                <div className="information_value">
                                    <div className="value">
                                        {/*TODO: remove hardcode NFTs Selected*/}
                                        = $2323
                                    </div>
                                    <span className="postscript">
                                    Equivalent in DM
                                    </span>
                                </div>
                            </div>
                            <div className="information_item">
                                <div className="information_title">
                                    DMP Result
                                    <span className='info_block'>
                                        <InfoIcon />
                                    </span>
                                </div>
                                <div className="information_value">
                                    <div className="value">
                                        {/*TODO: remove hardcode DMP Result*/}
                                        = $2323
                                    </div>
                                    <span className="postscript">
                                    Equivalent in DM
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="button_block">
                            <SimpleButton active={true}>
                                Claim Reward
                            </SimpleButton>
                        </div>
                    </div>
                    {/* <div className="referal_link">
                        <h3 className='block_title'>
                            Referal link
                        </h3>
                        <span className='block_subtitle'>
                            Earn 5% your referral commintments
                        </span>
                        <div className="block_link">
                            <a href="https://www.youtube.com/watch?v=L_LUpnjgPso&t=15505s">https://www.youtube.com/watch?v=L_LUpnjgPso&t=15505s</a><button onClick={() => copyLink('https://www.youtube.com/watch?v=L_LUpnjgPso&t=15505s')}>< CopyIcon /></button>
                        </div>
                        <div className="button_block">
                            <SimpleButton>
                                How It Works
                            </SimpleButton>
                        </div>
                    </div> */}
            </div>
        </div>
    )
}

export default RewardsModal;